<template>
  <v-dialog v-model="dialog" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-btn :class="iconClass" :color="iconColor" icon dark :style="iconStyle" v-on="on">
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title :class="tclass" style="word-break: unset">
        <slot name="header">
          <h4 class="font-weight-regular"> {{ title }} </h4>
        </slot>
      </v-card-title>

      <v-card-text>
        <slot></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :class="confirmClass" :color="confirmColor" text @click="dialog = false">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "InformationDialogIcon",
    props: {
      confirmClass: {
        type: String,
        default: ''
      },
      confirmColor: {
        type: String,
        default: 'secondary'
      },
      confirmText: {
        type: String,
        default: 'Got It!'
      },
      dialogWidth: {
        default: 500
      },
      icon: {
        type: String,
        default: 'mdi-information-outline'
      },
      iconClass: {
        type: String,
      },
      iconColor: {
        type: String,
        default: 'grey'
      },
      iconStyle: {
        type: String,
      },
      title: String,
      tclass: {
        type: String,
      },
    },
    data: () => ({
      dialog: false
    })
  }
</script>

<style scoped>

</style>