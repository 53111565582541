import CommonAPIService from './CommonHandler';

export class SessionsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getActiveSessions(router) {
    const orders = 'username';
    const url = `/api/sessions/active?orders=${orders}`;
    return this.CommonAPIService.getCall(url, router);
  }

  /**
   @param {string} username the user username
   @param {date | null} startDate looks for sessions from the starting date.
   @param {date | null} endtDate looks for sessions to the end date.
   @param {number | null} type the session numeric type. for more info check -> https://dominioninsurance.atlassian.net/wiki/spaces/SA/pages/1544650753/Sessions+type+names+and+values
   @param {number} page used for pagination, default is 1.
   */
  getLogsUserSessions(username, startDate = null, endDate = null, type = null, page = 1, router) {
    const orderBy = 'username,login';
    const directions = 'ASC,ASC';
    const limit = 1000;
    let url = `/api/sessions?action=log&username=${username}&orderBy=${orderBy}&directions=${directions}&page=${page}&limit=${limit}`;
    if (startDate) url += `&startDate=${startDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    if (type) url += `&type=${type}`;
    return this.CommonAPIService.getCall(url, router);
  }

  /**
   @param {string} username the user username
   @param {date | null} startDate looks for sessions from the starting date.
   @param {date | null} endtDate looks for sessions to the end date.
   @param {number | null} type the session numeric type. for more info check -> https://dominioninsurance.atlassian.net/wiki/spaces/SA/pages/1544650753/Sessions+type+names+and+values
   */
  getDailyUserSessions(username, startDate = null, endDate = null, type = null, router) {
    let url = `/api/sessions?action=daily&username=${username}`;
    if (startDate) url += `&startDate=${startDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    if (type) url += `&type=${type}`;
    return this.CommonAPIService.getCall(url, router);
  }

  /**
   @param {string} username the user username
   @param {date | null} startDate looks for sessions from the starting date.
   @param {date | null} endtDate looks for sessions to the end date.
   @param {number | null} type the session numeric type. for more info check -> https://dominioninsurance.atlassian.net/wiki/spaces/SA/pages/1544650753/Sessions+type+names+and+values
   */
  getPeriodUserSessions(username, startDate = null, endDate = null, type = null, router) {
    let url = `/api/sessions?action=period&username=${username}`;
    if (startDate) url += `&startDate=${startDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    if (type) url += `&type=${type}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getSessionById(id, router) {
    const url = `/api/sessions/${id}`;
    return this.CommonAPIService.getCall(url, router)
  }

  createSession(body, router) {
    const url = `/api/sessions`;
    return this.CommonAPIService.postCall(url, body, router);
  }

  updateSession(id, body, router) {
    const url = `/api/sessions/${id}`;
    return this.CommonAPIService.putCall(url, body, router);
  }

  deleteSession(id, router) {
    const url = `/api/sessions/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }
}

export default SessionsAPIService;
