<template>
  <v-dialog @input="$emit('input', $event)" max-width="360" :value="value">
    <v-card class="pl-1 pt-1 pr-0 mx-0" :key="refresh">
      <v-card-title>
        <h4 class="font-weight-regular">Note Readers</h4>
        <v-spacer/>
        <v-btn class="mt-n2 mr-n3" @click="$emit('close')" icon large text><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="mb-n4">
        <v-row>
          <v-col cols="2"><b>Re:</b></v-col>
          <v-col class="pr-3" cols="10" style="line-height: 1.3; margin-top: 2px" v-html="note.re"/>
        </v-row>
        <div class="px-0 pt-6 pb-6 mt-3 mb-n1 mx-0 scroll" style="max-height: 30vh; overscroll-behavior: contain"
             v-if="note.read && note.read.length !== 0">
          <v-row class="pr-0 mx-0 my-n7" :class="{'caption':$vuetify.breakpoint.xs}" :key="read.uname" v-for="read in note.read">
            <v-col class="px-0 mx-0" cols="8">
              Read &nbsp &nbsp {{ moment(read.readers_stamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY \xa0 hh:mm A')}}
            </v-col>
            <v-col class="px-0 mx-0" cols="4">&nbsp &nbsp by &nbsp {{ read.uname || '' }}</v-col>
          </v-row>
        </div>
        <div v-else-if="loading">
          <div class="my-6 text-center"><v-progress-circular indeterminate/></div>
        </div>
        <div class="mt-3" v-else>No one has marked this note read.</div>
      </v-card-text>

      <v-divider style="margin-bottom: -2px"/>
      <v-card-actions class="mx-4"><br>
        <v-btn color="secondary" @click="markNoteAsRead(note)"
          v-if="(note.userHasNotRead === true || !note.read || (note.read && !note.read.length)) && !loading">
          Mark this note read
        </v-btn>
        <v-spacer/>
        <v-btn color="secondary darken-1" @click="$emit('close')" x-large text><b>Close</b></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { NotesAPIService } from '@/servicehandlers/NotesAPIService';

  const notesAPIService = new NotesAPIService();

  export default {

    props: {
      note: Object,
      username: String,
      value: Boolean
    },

    data() {
      return {
        loading: false,
        refresh: false,
      }
    },

    watch: {
      note() {
        this.getReaders(this.note);
      }
    },

    methods: {
      getReaders(note, marked) {
        if (note.alreadyGotReaders === true) return;//if already called getRead, don't call again (unless marked 'read')
        this.loading = true;
        notesAPIService.getRead(note.nid, this.$router).then((read) => {
          read.sort((a, b) => { return new Date(b.readers_stamp) - new Date(a.readers_stamp)} );//sort DESC by read date
          note.read = read.filter(x => x.uname !== null);
          note.userHasNotRead = read.filter(x => x.uname === this.username).length === 0;
          note.alreadyGotReaders = true;
          this.loading = false;
          if (marked) this.refresh = !this.refresh;
        })
      },

      markNoteAsRead(note) {
        this.loading = true;
        notesAPIService.updateNoteAsRead(note.nid, this.$router).then(response => {
          note.alreadyGotReaders = false;// allow getReaders to call getRead again to get updated readers list with user
          this.getReaders(note, true);
        });
      },
    },
  }
</script>

<style scoped>
</style>
