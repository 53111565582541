<template>
  <v-app :style="{background: $vuetify.theme.themes.light.background}">
    <div id="nav">
      <v-app-bar style="z-index: 1100" app v-if="!sideMenu && isLoggedIn" ripple fill-height dense dark color="primary">
        <v-btn class="mr-5" @click="switchMenu()" icon>
          <img id="toolbarLogo" src="@/assets/logo_whtDominion.png"/>
        </v-btn>
        <div class="d-flex flex-column">
          <v-btn v-if="isLoggedIn" @click="goToHome" icon>
            <v-icon class="px-3 mx-4 mt-n1">mdi-home</v-icon>
          </v-btn>
          <div class="caption mr-1 mt-n4">&copy; {{ new Date().getFullYear() }}</div>
        </div>
        <button-tip @click="goToJobs" icolor="white" icon iname="mdi-briefcase-outline" tip="See Jobs"
                    v-can="'firm-view'" v-if="isLoggedIn && $vuetify.breakpoint.name !== 'xs'"/>
        <!--          TODO add user pref button below when ready-->
<!--        <v-btn @click="userPref = true" icon><v-icon-->
<!--               class="px-3 mx-4">mdi-account-settings-outline</v-icon>-->
<!--        </v-btn>-->
        <div class="flex-grow-1"></div>
        <v-toolbar-title>{{ title }} {{ $root.deploymentEnv }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-toolbar-items align-center>
          <v-menu class="spacing-hover" open-on-hover offset-y>
            <template v-slot:activator="{ on }">
              <v-btn elevation="0" color="primary" dark v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list>
              <span class="ml-4"></span>ADMIN: &nbsp &nbsp
              <v-list-item v-for="link in manageLinks" v-can="link.scope" :key="link.friendly" :to="link.path">
                <v-list-item-title color="primary">{{ link.friendly }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu class="spacing-hover" open-on-hover offset-y>
            <template v-slot:activator="{ on }">
              <v-btn elevation="0" color="primary" dark v-on="on">
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <span class="ml-4"></span>REPORTS: &nbsp &nbsp
              <v-list-item v-for="link in reportLinks" v-can="link.scope" :key="link.friendly" :to="link.path">
                <v-list-item-title color="primary">{{ link.friendly }}</v-list-item-title>
              </v-list-item>
              <v-list-item class="hoverlink" v-can="'claim-view'">
                <v-list-item-title @click="reportPopup('renewal')" color="primary" style="cursor: pointer">Renewal
                  <v-icon class="mb-1">mdi-open-in-new</v-icon></v-list-item-title>
              </v-list-item>
              <v-list-item class="hoverlink" v-can="'claim-view'">
                <v-list-item-title @click="reportPopup('lossRun')" color="primary" style="cursor: pointer">Loss Run
                  <v-icon class="mb-1">mdi-open-in-new</v-icon></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu class="spacing-hover" open-on-hover offset-y>
            <template v-slot:activator="{ on }">
              <v-btn elevation="0" color="primary" dark v-on="on">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <v-list>
              <span class="ml-4"></span>SEARCH: &nbsp &nbsp
              <v-list-item v-for="link in searchLinks" v-can="link.scope" :key="link.friendly" :to="link.path">
                <v-list-item-title color="primary">{{ link.friendly }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="text-center d-flex align-center">
            <v-tooltip bottom nudge-bottom="6">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on" @click="logout()"
                       elevation="0" background-color='primary'>
                  <v-icon background-color="primary" class="ml-n3 mr-xs-8">mdi-logout</v-icon></v-btn>
              </template>
              <span>Logout</span>
            </v-tooltip>
          </div>
          <v-btn class="hidden-sm-and-up"  elevation="0" color="primary"></v-btn>
        </v-toolbar-items>
      </v-app-bar>
    </div>

    <v-navigation-drawer app v-if="sideMenu && isLoggedIn" dark color="primary" permanent height="100%" width="63px">
      <template v-slot:prepend>
        <v-list>
          <v-list-item class="mt-n3 mb-n3" style="margin-left: -7px">
            <v-list-item-avatar>
              <v-btn @click="switchMenu()" icon>
                <img id="sidebarLogo" src="@/assets/logo_whtDominion.png"/></v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </template>
      <v-divider></v-divider>
      <v-card class="d-flex justify-space-between flex-column" color="primary" elevation="0"
              :style="{ height: `calc(100vh - 60px)` }">
        <v-card color="primary" elevation="0">
          <v-btn v-if="isLoggedIn" @click="goToHome" icon class="ml-3">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </v-card>
        <v-card color="primary" elevation="0">
          <!--          TODO add user pref button below when ready-->
<!--                  <v-btn class="ml-3" @click="userPref = true" icon>-->
<!--                    <v-icon>mdi-account-settings-outline</v-icon>-->
<!--                  </v-btn>-->
        </v-card>
        <v-card color="primary" elevation="0">
          <v-menu open-on-hover offset-x :nudge-left="20" right ml-n4 z-index="99">
            <template v-slot:activator="{ on }">
              <v-btn elevation="0" color="primary" dark style="margin-left: -2px" v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list>
              <span class="ml-4"></span>ADMIN: &nbsp &nbsp
              <v-list-item v-for="link in manageLinks" v-can="link.scope" :key="link.friendly" :to="`${link.path}`">
                <v-list-item-title color="primary">{{ link.friendly }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card>
        <v-card color="primary" elevation="0">
          <v-menu open-on-hover offset-x :nudge-left="20" right ml-n4 z-index="99">
            <template v-slot:activator="{ on }">
              <v-btn elevation="0" color="primary" dark style="margin-left: -2px" v-on="on">
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <span class="ml-4"></span>REPORTS: &nbsp &nbsp
              <v-list-item v-for="link in reportLinks" v-can="link.scope" :key="link.friendly" :to="`${link.path}`">
                <v-list-item-title>{{ link.friendly }}</v-list-item-title>
              </v-list-item>
              <v-list-item class="hoverlink" v-can="'claim-view'">
                <v-list-item-title @click="reportPopup('renewal')" color="primary" style="cursor: pointer">Renewal
                  <v-icon class="mb-1">mdi-open-in-new</v-icon></v-list-item-title>
              </v-list-item>
              <v-list-item class="hoverlink" v-can="'claim-view'">
                <v-list-item-title @click="reportPopup('lossRun')" color="primary" style="cursor: pointer">Loss Run
                  <v-icon class="mb-1">mdi-open-in-new</v-icon></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card>
        <v-card color="primary" elevation="0">
          <v-menu open-on-hover offset-x :nudge-left="20" right z-index="99">
            <template v-slot:activator="{ on }">
              <v-btn elevation="0" class="ml-0" color="primary" dark v-on="on">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <v-list>
              <span class="ml-4"></span>SEARCH: &nbsp &nbsp
              <v-list-item v-for="link in searchLinks" v-can="link.scope" :key="link.friendly" :to="`${link.path}`">
                <v-list-item-title>{{ link.friendly }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card>
        <v-card color="primary" elevation="0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4 mb-4" icon dark v-on="on">
                <v-icon @click="logout()">mdi-logout</v-icon>
              </v-btn>
            </template>
            <span>
            Logout
          </span>
          </v-tooltip>
        </v-card>
        <v-spacer></v-spacer>
      </v-card>
    </v-navigation-drawer>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <router-view></router-view>
    </v-main>

    <idle-dialog v-if="$store.state.timedOut" :value="$store.state.timedOut"></idle-dialog>
    <theme-editor v-model="userPref"></theme-editor>
<!--    <v-footer app color="primary" dark style="z-index: 99">-->
<!--      <v-spacer></v-spacer>-->
<!--      <div>&copy; {{ new Date().getFullYear() }}</div>-->
<!--    </v-footer>-->
  </v-app>
</template>

<script>
  import { CommonAPIService } from '@/servicehandlers/CommonHandler';
  import ActionDialog from "@/components/ActionDialog";
  import ThemeEditor from "@/components/ThemeEditor";
  import IdleDialog from "./components/idle/IdleDialog";

  const apiService = new CommonAPIService();
  export default {
    name: 'APPPAGE',
    components: {IdleDialog, ActionDialog, ThemeEditor},
    data: () => ({
      manageLinks: [
        {friendly: 'MGA Commission Rates', path: '/manage/commissions', scope: 'mgacommission-manage'},
        {friendly: 'MGA Billing', path: '/manage/mgaBilling', scope: 'mgabilling-manage'},
        {friendly: 'Producer Commission Rates', path: '/manage/producerCommissions', scope: 'producercommission-manage'},
        {friendly: 'Roles', path: '/manage/roles', scope: 'role-edit'},
        {friendly: 'Tax Rates', path: '/manage/taxes', scope: 'tax-manage'}
      ],
      reportLinks: [
        {friendly: 'Aggregate Reports', path: '/reports/reportsSearch', scope: 'report-view'},
        {friendly: 'Bind Authority', path: '/reports/bindauthority', scope: 'bindauthority-view'},
        {friendly: 'Bordereaux', path: '/reports/bordereau', scope: 'bordereau-view'}
      ],
      searchLinks: [
        {friendly: 'Claims', path: '/search/claims/claimsSearch', scope: 'claim-view'},
        {friendly: 'Contacts', path: '/search/contacts/contactsSearch', scope: 'contact-view'},
        {friendly: 'Firms/Jobs', path: '/search/firms/firmsSearch', scope: 'firm-view'}
      ],
      title: process.env.VUE_APP_TITLE,
      userPref: false
    }),
    computed: {
      isLoggedIn() {
        return this.$store.getters.loggedIn;
      },
      sideMenu() {
        return this.$store.getters.sideMenu;
      },
    },

    mounted() {
      if (!this.$store.getters.lists.length) this.$_getLists();// gets lists for dropdowns throughout
    },

    methods: {
      reportPopup(report) {
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/reports/' + report, '_blank',
          'resizeable=yes, width=' + (1400)
          + ', left=' + (500)
          + ', top=' + (22)
          + ', height=' + (900));
      },

      switchMenu() {
       this.$store.dispatch('SET_SIDE_MENU', !this.sideMenu);
      },

      goToJobs() {
        this.$router.push({name: 'FirmsSearch',}).catch(err => {});
      },

      goToHome() {
        // let homePage = this.$store.state.userPreferences.home === 'claims'
        //   ? 'ClaimsSearch' : 'FirmsSearch';

        let homePage = 'Home';

        this.$router.push({
          name: homePage
        }).catch(err => {});
      },

      logout() {
        this.$store.dispatch('LOGOUT');
      },

    },

  }
</script>
<style lang="scss">
  @import '@/styles/app.scss';
  @import '@/styles/index.scss';
</style>
<style>
  .v-data-table.short-table-rows td { height: 18px !important }
  /* styles for small text in input fields for use throughout */
  .smallmessage .v-messages__message {
    font-size: 9.9px !important;
  }
  .smallfield .v-label {
    font-size: 13px !important;
  }
  .smalltext.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    font-size: 14px !important;
  }
  /* styles for dropdowns throughout */
  .compact .v-list-item{
      min-height: 28px !important;
      height: 28px !important;
  }
  .long {
    max-height: calc(100vh - 200px) !important;
    overflow: scroll !important;
    margin-left: 16px;
  }
  .narrow.v-autocomplete__content.v-menu__content {
    min-width: 180px !important;
  }
  .short {
    max-height: 255px !important;
  }
  .xshort {
      max-height: 138px !important;
  }
  .v-autocomplete__content.v-menu__content {
    background-color: white;/* without this the dropdown boxes have top&bottom transparent areas if scrolled quickly */
  }
</style>

