<template>
  <v-card class="rounded-0">
    <v-toolbar color="secondary" dark height="28px" style="margin-bottom: -28px; maxHeight: 28px; z-index: 8">
      <v-toolbar-title>
        <v-row class="pl-2" style="margin-top: -8px">
          <button-tip btnclass="ml-0 mr-n2" @click="getNotes()" icolor="white" icon iname="mdi-update" tip="Refresh"/>
          <button-tip btnclass="mx-n2" @click="expanded=[]" icolor="white" icon
                      iname="mdi-collapse-all-outline" tip="Close All"/>
          <span v-if="items === 'News'" :style="$vuetify.breakpoint.name === 'xs' ? 'margin-top: -3px' : ''">
            <span class="ml-1 mr-n1" :class="{'caption':$vuetify.breakpoint.xs}">My</span>
            <button-tip btnclass="mx-n1" @click="news = 'My'" icolor="white" icon iname="mdi-toggle-switch-outline"
                        tip="Switch to My News" v-if="news === 'All'" xlarge/>
            <button-tip btnclass="mx-n1" @click="news = 'All'" icolor="white" icon iname="mdi-toggle-switch-off-outline"
                        tip="Switch to All News" v-if="news === 'My'" xlarge/>
            <span class="ml-n1 mr-1 pr-2" :class="{'caption':$vuetify.breakpoint.xs}">All News</span>
          </span>
          <span class="pl-2 pt-2 pr-4" :class="{'caption':$vuetify.breakpoint.xs}"
                :style="$vuetify.breakpoint.name === 'xs' ? 'margin-top: 6px' : ''" v-else>
            {{ items === 'Appts' ? 'Appointments' : 'Events' }}</span>
        </v-row>
      </v-toolbar-title>
      <span :class="{'caption':$vuetify.breakpoint.xs}" style="margin: 3px -1px -1px 8px">
        {{ notes.length > 0 ? notes.length.toLocaleString('en', {useGrouping:true}) : '' }}</span>
      <information-dialog-icon confirm-class="mr-3 mb-1 title" dialog-width="550" icon-color="white"
                               icon-style="margin: 2px 0 -1px" tclass="headline grey lighten-2 mb-8"
                               title="News Info" v-if="items === 'News'">
        <p>If the news item is
          <span style="color: red">xtreme</span>, the date will be red. Ex:
          <span style="color: red">05 Mar 2022 12:46 AM</span>
        </p>
        <p>If the news item is
          <span style="color: blue">urgent</span>, the date will be blue. Ex:
          <span style="color: blue">01 Dec 2018 11:11 AM</span>
        </p>
        <p>Click <v-icon>mdi-collapse-all-outline</v-icon> to close expanded notes in the corresponding table.</p>
        <p>Slide the switch to "All News" to see everyone's news.</p>
        <p>Slide the switch to "My" to see only your news.</p>
      </information-dialog-icon>
      <v-spacer/>
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-n3 mb-1 mr-1"
        clearable
        dense
        hide-details
        :label="$vuetify.breakpoint.name === 'xs' ? '' : 'Search'"
        single-line
        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: x-small' : ''"
        style="margin-top: 2px; max-width: 26vw"
        v-model="search"/>
    </v-toolbar>
    <v-data-table class="elevation-2 short-table-rows"
                  @click:row="rowClick"
                  dense
                  :expanded.sync="expanded"
                  fixed-header
                  :headers="headers"
                  :height="expandedTable === true ? '80vh': ['xs','sm'].includes($vuetify.breakpoint.name) ? '27vh':'35.5vh'"
                  hide-default-footer
                  @item-expanded="getNoteFiles"
                  item-key="nid"
                  :items="notes"
                  :items-per-page="-1"
                  :loading="loading"
                  loading-text="Data loading... Please wait..."
                  :mobile-breakpoint='0'
                  :search="search"
                  :sort-by="'cstamp'"
                  :sort-desc="[['Events', 'Appts'].includes(items) ? false : true]"
                  show-expand
                  single-select
                  :style="$_cssVars">
      <template v-slot:expanded-item="{ headers, item }">
        <note-expansion :colspan="headers.length" :files="item.files || []" :key="refreshNoteFiles" :nid="item.nid"
          :note="item.note.replace(/(?:\r\n|\r|\n)/g, '<br>')" :tasks="item.tasks" :width="'60%'"/>
      </template>
      <template v-if="items === 'News'" v-slot:item.actions="{ item }">
        <v-lazy>
          <v-row class="mt-n1 mb-n2">
            <button-tip btnclass="mr-2" btnstyle="margin-top: -2px" @click="read(item)" icon iname="mdi-glasses" tip="See/Mark Read"/>
            <EditNoteOpen class="mb-n3" style="margin-top: -1px" v-bind="{ editPopup: true, note: item, username }"
              v-can="'note-edit'" v-if="(!['done','cancel'].includes(item.status) && item.staff === username)
              || (groups.includes('admin'))"/>
          </v-row>
        </v-lazy>
      </template>
      <template v-slot:item.cstamp="{ item }">
        <div :style="{color: (item.status === 'xtreme'  ? 'red' : item.status === 'urgent'  ? 'blue' : 'black' )}">
          {{ moment(item.cstamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY \xa0 hh:mm A \xa0 ddd')}}
        </div>
      </template>
      <template v-slot:item.date="{ item }">
        <div :style="{color: (item.status === 'xtreme'  ? 'red' : item.status === 'urgent'  ? 'blue' : 'black' )}">
          {{ item.date }}
        </div>
      </template>
      <template v-slot:item.re="{ item }">
        <div v-html="replaceErrorChar(item.re)"></div>
      </template>
    </v-data-table>

    <HomeNotesRead @close="readersDialog = false" :note="note" :second-button="false" :username="username"
                   v-model="readersDialog"/>
  </v-card>
</template>

<script>
import EditNoteOpen from '@/components/search/firms/firmNotesTable/EditNoteOpen';
import HomeNotesRead from './HomeNotesRead.vue';
import NoteExpansion from '@/components/search/firms/firmNotesTable/NoteExpansion';
import { NotesAPIService } from '@/servicehandlers/NotesAPIService';

const notesAPIService = new NotesAPIService();

export default {

  components: { EditNoteOpen, HomeNotesRead, NoteExpansion },
  props: ['items', 'expandedTable'],

  data() {
    return {
      expanded: [],
      groups: [],
      loading: false,
      news: 'My',// 3/7/2025 JCH - Will Stewart said this should be default
      note: {},// note to pass to HomeNotesRead.vue dialog - to show who has read that note
      notes: [],
      readersDialog: false,
      refreshNoteFiles: false,
      search: '',
      username: '',
    }
  },

  watch: {
    expanded() {
      this.$emit('expandedLengthChanged', this.expanded.length, this.items );
    },
    news() {
      this.getNotes();
    },
    '$vuetify.breakpoint.name'() {// if not small screen, trigger reset of tables' heights
      this.$emit('resize', this.expanded.length, this.items );
    }
  },

  computed: {
    headers() {
      let hdrEvents = [// column headers for events
        {value: 'cstamp',width: '230px'},
        {value: 're',    width: '700px',align: 'start' },
      ];
      let news = [// add for news
        {value: 'sort',  width: '500px'},
      ];
      let hdrAppointments = [...hdrEvents, ...[{value: 'staff', width: '100px'}]];// add staff for appointments
      let hdrNews = [...[{value: 'actions', width: '100px'}],...hdrAppointments, ...news];// add actions & news
      let hdrs = this.items === 'News' ? hdrNews: this.items === 'Appts' ? hdrAppointments : hdrEvents;
      return hdrs;
    },
  },

  created() {
    document.title = 'Home';
    this.username = this.$store.getters.username;
    this.groups = this.$store.getters.groups;
    this.getNotes();
  },

  methods: {
    getNoteFiles(expanded) {
      if (expanded.value === false) return;// only check for files if item expanded (true), not if it was closed (false)
      if (!(expanded.item.files && expanded.item.files.length)) {// if there are not files already call API
        notesAPIService.getNoteFiles(expanded.item.nid, this.$router).then((files) => {
          expanded.item.files = files;
          this.refreshNoteFiles = !this.refreshNoteFiles;
        });
      }
    },

    getNotes() {
      this.loading = true;
      // remove final s and change to lowercase to get note type of 'event', or 'appt' for getCalendarNotesByType
      let type = this.items.toLowerCase().substring(0, this.items.length-1);
      if (this.items === 'News' && this.news === 'My') {
        notesAPIService.getNewsNotesByUsername(this.username, this.$router).then(notes => {
          this.processNotes(notes);
        });
      }
      if (this.items === 'News' && this.news === 'All') {
        notesAPIService.getAllNewsNotes(this.$router).then(notes => {
          this.processNotes(notes);
        });
      }
      if (this.items === 'Events' || this.items === 'Appts') {
        notesAPIService.getCalendarNotesByType(type, this.$router).then(notes => {
          this.processNotes(notes);
        });
      }
    },

    processNotes(notes) {
      this.notes = notes;
      for (let note of this.notes) {// for each note, if expanded, get files
        if (this.expanded.some(x => x.nid === note.nid)) {
          notesAPIService.getNoteFiles(note.nid, this.$router).then((files) => {
            note.files = files;
            this.refreshNoteFiles = !this.refreshNoteFiles;
          });
        }
      }
      this.loading = false;
    },

    read(note) {// open dialog to mark note read and/or show readers list
      this.note = note;
      this.readersDialog = true;
    },

    replaceErrorChar(text) {
      return text.replace(/�/g, "'");
    },

    rowClick: function (item, row) {
      row.select(true);
    },

  },

}
</script>

<style>
  .v-data-table.short-table-rows td { height: 18px !important }
</style>
