<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
<!--            class="pl-1"-->
      <v-btn
            :a="a"
            class=""
            :class="btnclass"
            @click="raiseClickEvent()"
            :color="color"
            :disabled="disabled"
            :elevation="elevation"
            :fab="fab"
            :icon="icon"
            :small="small"
            :stop="stop"
            :style="btnstyle"
            :text="text"
            :tile="tile"
            v-on="tip ? on : null"
            :x-large="xlarge"
            :x-small="xsmall"
        ><a :href="href">
          <v-icon
                :color="icolor"
                :class="iclass"
                :small="ismall"
                :style="istyle"
                v-if="icon || fab"
      >
          {{ iname }}
          </v-icon>
        </a>
        <span class="hidden-sm-and-down">{{ hideSmDown +  txt + hideSmDownAfter}}</span>
        <span class="hidden-md-and-up">{{ txt }}</span>
      </v-btn>
    </template>
    <span> {{ tip }} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ButtonTip",
  props: {
    a: { type: Boolean, default: false },
    btnclass: { type: String, default: "" },
    btnstyle: { type: String, default: "" },
    color: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    elevation: { type: String, default: "" },
    fab: { type: Boolean, default: false },
    hideSmDown: { type: String, default: "" },
    hideSmDownAfter: { type: String, default: "" },
    href: { type: String, default: "" },
    icon: { type: Boolean, default: false },
    iname: { type: String, default: "" },
    iclass: { type: String, default: "" },
    icolor: { type: String, default: "" },
    ismall: { type: Boolean, default: false },
    istyle: { type: String, default: "color: rgba(0, 0, 0, 0.54)" },// custom grey default
    small: { type: Boolean, default: false },
    stop: { type: Boolean, default: false },
    tile: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    txt: { type: String, default: "" },
    tip: { type: String, default: "" },
    xlarge: { type: Boolean, default: false },
    xsmall: { type: Boolean, default: false },
  },

  data: () => ({
    space: ' ',
  }),

  methods: {
    raiseClickEvent() {
      if (this.stop) event.stopPropagation();// button's click handler called not parent's
      if (!this.href) event.preventDefault();// prevent reloading unless href, then needed for download
      this.$emit('click');
    },
  },
};
</script>