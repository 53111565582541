<template>
  <div>
    <button-tip @click="copyNote()" icon iname="mdi-content-copy" tip="Copy Note"
                v-if="copy && $_can('note-edit') && note.type !== 'web in'"/>
    <button-tip btnclass="mx-n1" @click="editNoteInDialog()" icon iname="mdi-open-in-app" tip="Edit Dialog"
                v-if="editDialog"/>
    <button-tip @click="editNotePopup(note)" icon iname="mdi-open-in-new" :ismall="ismall"
                tip="Edit Popup" v-if="editPopup"/>
    <button-tip btnclass="ml-1 mt-n4 mr-2" @click="createNewNote()" color="secondary" elevation="5" fab icolor="white"
                iname="mdi-plus" tip="Create Note" v-if="createNew" xsmall/>

    <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" persistent :retain-focus="false" v-model="openEditNoteInDialog">
      <v-card>
        <div>
          <EditNoteEmail
              v-if="openEditNoteInDialog"
              :id="noteId"
              :dialog="true"
              @refresh="$emit('refresh')"
              @close-dialog="openEditNoteInDialog = false"
          />
        </div>
      </v-card>
    </v-dialog>

    <ActionDialog :value="noteTaken" :max-width="700" divider confirm-text="OK" :second-button="false"
                  title="Note Taken" confirm-color="error" close-text="" @confirm="noteTaken = false">
      <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
        {{ noteOwner ?  noteOwner + ' has this note' : 'This note is not in your name' }}.
        To edit a note, the note must be in your name.
      </v-alert>
    </ActionDialog>

    <ActionDialog :value="noteTakenAdmin" :max-width="700" divider confirm-text="Proceed"
                  title="Note is Taken" confirm-color="error" close-text="Cancel"
                  @confirm="noteTakenAdmin = false, editTakenNote()" @close="noteTakenAdmin = false">
      <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
        Take note from {{ noteOwner || 'owner' }}?
      </v-alert>
    </ActionDialog>
  </div>
</template>

<script>
  import ActionDialog from '../../../ActionDialog';
  import EditNoteEmail from '@/components/search/firms/EditNoteEmail';
  import { NotesAPIService } from '@/servicehandlers/NotesAPIService';
  import { RecipientAPIService } from '@/servicehandlers/RecipientAPIService';

  const notesAPIService = new NotesAPIService();
  const recipient = new RecipientAPIService();

  export default {
    name: 'EditNoteOpen',
    components: { ActionDialog, EditNoteEmail },
    props: ['copy', 'createNew', 'editDialog', 'editPopup', 'firm', 'note', 'username', 'ismall'],
    data: () => ({
      baseUrl: window.location.origin,
      editTakenNoteInPopup: false,
      groups: [],
      noteId: '',
      noteOwner: false,
      noteTaken: false,
      noteTakenAdmin: false,
      openEditNoteInDialog: false,
    }),

    created() {
      this.groups = this.$store.getters.groups;
    },

    methods: {
      createNewNote() {
        const params = {fid: this.firm.fid, type: "memo", re: "NEW NOTE", opener: this.username,
          closer: this.username, staff: this.username};
        notesAPIService.createNote(params, this.$router).then((note) => {
          this.open(note);
          this.$emit('refresh'); // refresh status & notes table to show new note, reset batches (only works from dialog)
        }).catch((e) => this.$refs.errorDialog.showError({ forDevelopers: e }));
      },

      copyNote() {
        notesAPIService.getNote(this.note.nid, this.$router).then((data) => {// get note in case changed
          let noteToCopy = data;
          if (!!this.$parent.nextJobNid && this.$parent.search[this.$parent.firm.fid]
              === this.$parent.nextJobNid.toString()) {
            this.$parent.search[this.$parent.firm.fid] = '';
            this.$parent.nextJobNid = '';
          }
          const parms = {
            closer: this.username,
            fid: noteToCopy.fid,
            fullName: noteToCopy.fullName,
            note: noteToCopy.note,
            opener: this.username,
            signer: noteToCopy.signer,
            plid: noteToCopy.plid,
            re: 'Copy of: ' + noteToCopy.re,
            staff: this.username,
            status: 'normal',
            type: 'memo',
          };
          notesAPIService.createNote(parms, this.$router).then((data) => {
            let nid = data.nid;
            // copy recipients from original to new copy
            recipient.list(this.note.nid, this.$route).then((recipients) => {
              let recipientsCopy = [];
              for (let recipient of recipients) {
                recipientsCopy.push({plid: recipient.plid, nid: data.nid,
                  to: recipient.to, cc:recipient.cc, bcc:recipient.bcc})
              }
              return recipient.create(data.nid, recipientsCopy, this.$route)
                .catch((e) => this.$refs.errorDialog.showError({ forDevelopers: e }));
            });

            notesAPIService.getNoteFiles(this.note.nid, this.$router).then((filesData) => {
              if (!!filesData) {
                let files = this._.uniqBy(filesData, 'loid');// remove duplicate files with same loid
                // get all the loids from the files, so they can be connected to the new (copied) note
                let loidsToConnect = files.map(a => a.loid);
                notesAPIService.updateFiles(nid, loidsToConnect, this.$router);
              }
              this.$emit('refresh');
            }).catch((e) => this.$refs.errorDialog.showError({ forDevelopers: e }));
            this.open(data);
          }).catch((e) => this.$refs.errorDialog.showError({ forDevelopers: e }));
        }).catch((e) => this.$refs.errorDialog.showError({ forDevelopers: e }));
      },

      editTakenNote() {
        this.editTakenNoteInPopup === true ? this.open(this.note) : this.openEditNoteInDialog = true;
      },

      editNotePopup(note) {
        // get current note to check if taken (note.staff is no longer this.username)
        notesAPIService.getNote(note.nid, this.$router).then((note) => {// use nid to get note
          if (note) {
            if (note.staff === this.username) {
              this.open(note);
            } else {
              this.noteOwner = note.staff;
              this.editTakenNoteInPopup = true;
              this.groups.includes('admin') ? this.noteTakenAdmin = true : this.noteTaken = true;
            }
          }
        }).catch((e) => this.$refs.errorDialog.showError({ forDevelopers: e }));
      },

      open(note) {
        window.open(this.baseUrl +  '/EditNoteEmail/' +  note.nid, '_blank',
            'resizeable=yes, width=' + (1080)
            + ', left=' + (500)
            + ', top=' + (45)
            + ', height=' + (900));
      },

      editNoteInDialog() {
        // get current note to check if taken (note.staff is no longer this.username)
        notesAPIService.getNote(this.note.nid, this.$router).then((note) => {// use nid to get note
          if (note) {
            this.noteId = note.nid;
            if (note.staff === this.username) {
              this.openEditNoteInDialog = true;
            } else {
              this.noteOwner = note.staff;
              this.editTakenNoteInPopup = false;
              this.groups.includes('admin') ? this.noteTakenAdmin = true : this.noteTaken = true;
            }
          }
        }).catch((e) => this.$refs.errorDialog.showError({ forDevelopers: e }));
      },

    },
  };
</script>

<style>

</style>



