<template>
  <v-card class="rounded-0">
    <v-toolbar class="elevation-5" color="secondary" dark height="28px">
      <v-toolbar-title class="mt-1">
        <v-row class="pl-5" style="margin-top: -12px">
          <span class="ml-1 mr-n1" :class="{'caption':$vuetify.breakpoint.xs}"
                :style="$vuetify.breakpoint.name === 'xs' ? 'margin-top: 16px' : 'margin-top: 10px'">Active
            <span v-if="$vuetify.breakpoint.name !== 'xs'"> Sessions</span>
          </span>
          <button-tip btnclass="mx-n1" @click="get = 'Active Sessions', getActiveSessions()" icolor="white" icon
                      iname="mdi-toggle-switch-outline" tip="Switch to Active Sessions"
                      v-if="get === 'Time Entries'" xlarge/>
          <button-tip btnclass="mx-n1" @click="get = 'Time Entries', gettingActiveSessions = false, list = []"
                      icolor="white" icon iname="mdi-toggle-switch-off-outline" tip="Switch to Time Entries"
                      v-if="get === 'Active Sessions'" xlarge/>
          <span class="ml-n1 mr-1 pr-2" :class="{'caption':$vuetify.breakpoint.xs}"
                :style="$vuetify.breakpoint.name === 'xs' ? 'margin-top: 16px' : 'margin-top: 10px'">Time Entries</span>
        </v-row>
      </v-toolbar-title>
      <span :class="{'caption':$vuetify.breakpoint.xs}" style="margin: 5px 0 0 8px">
        {{ listLength > 0 ? listLength.toLocaleString('en', {useGrouping:true}) : ''}}</span>
      <information-dialog-icon confirm-class="mr-3 mb-1 title" dialog-width="550" icon-color="white"
                               icon-style="margin: 2px 0 -1px -4px" tclass="headline grey lighten-2 mb-8"
                               title="Active Sessions & Time Entries">
        <p>For Time Entries, make sure switch is set to "Time Entries", fill fields and click LOG, DAILY, or PERIOD.</p>
        <p>If time period is more than one year, only DAILY and PERIOD will be enabled.</p>
        <p>For period hours for all users, make sure switch is set to "Time Entries", select "All" for the username,
          fill remaining fields, and click PERIOD.</p>
        <p>For a list of all users with currently active sessions, move switch to "Active Sessions". This will also
          clear previous search results for "Time Entries".</p>
      </information-dialog-icon>
      <v-spacer/>
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-n3 mb-1 mr-1"
        clearable
        dense
        hide-details
        :label="$vuetify.breakpoint.name === 'xs' ? '' : 'Search'"
        single-line
        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: x-small' : ''"
        style="margin-top: 1px; max-width: 26vw"
        v-model="search"/>
    </v-toolbar>
    <v-form ref="form" v-model="valid">
      <v-data-table class="py-2 elevation-2 med-table-rows"
                  dense
                  fixed-header
                  :headers="headers"
                  :height="['xs','sm'].includes($vuetify.breakpoint.name) ? '225px':'45vh'"
                  hide-default-footer
                  :items="list"
                  :items-per-page="-1"
                  :key="headers[0].width"
                  :loading="loading"
                  loading-text="Data loading... Please wait..."
                  multi-sort
                  :mobile-breakpoint='0'
                  :no-data-text="noDataText"
                  :search="search"
                  :style="$_cssVars">
        <template v-slot:header="{ props }">
          <th class="pb-3" :key="field.value + refresh" v-for="field in headers">
            <smart-input
              class="ml-4 pt-3 mb-n2"
              dense
              :disabled="field.value === 'username' && !$_can('hours-override')"
              :items="dropdownItems[field.list]"
              :key="refresh"
              :label="field.text"
              single-line
              :style="{ fontWeight: 'lighter', width: field.width, fontSize: '14px' }"
              :type="'VAutocomplete'"
              v-if="!gettingActiveSessions && (field.value === 'username' || field.value === 'type')"
              v-model="hoursSearch[field.value]"/>
            <div class="px-0 mb-4" :key="refreshButtons" v-else-if="field.value === 'time'">
              <v-row class="ml-1 nopad d-flex justify-center">
                <v-btn class="box" @click="getHours('Log')" color='secondary'
                       :disabled="!valid || periodMoreThanYear || !hoursSearch.username || hoursSearch.username === 'All'
                        || !hoursSearch.formatted_login || !hoursSearch.logout"
                       min-width="43" small>Log</v-btn>
                <v-btn class="box" @click="getHours('Daily')" color='secondary'
                       :disabled="!valid || !hoursSearch.username || hoursSearch.username === 'All'
                        || !hoursSearch.formatted_login || !hoursSearch.logout"
                       min-width="53" small>Daily</v-btn>
                <v-btn class="box" @click="getHours('Period')" color='secondary' :disabled="!valid
                        || !hoursSearch.formatted_login || !hoursSearch.logout"
                       min-width="63" small>Period</v-btn>
              </v-row>
              <v-row v-if="list.sum">
                <div class="mt-3">sum</div>
                <v-col class="d-flex justify-end" style="margin-right: 34px">{{ list.sum }}</v-col>
              </v-row>
              <v-row class="mt-n6 mb-n3" v-if="list.core">
                <div class="mt-3">core</div>
                <v-col class="d-flex justify-end" style="margin-right: 34px">{{ list.core }}</v-col>
              </v-row>
              <v-row class="my-n5" v-else></v-row>
            </div>
            <div v-if="['formatted_login','logout'].includes(field.value)" class="mx-2 down">
              <date-picker-formatted
                clearable
                dense
                :date-formatted-prop="hoursSearch[field.value]"
                :format="dateFormat"
                :format-loosely-valid="dateFormatLooselyValid"
                :label="field.text"
                :max="today"
                :menuNudgeRight="$vuetify.breakpoint.name === 'xs' ? '0' : '120'"
                :menuNudgeTop="$vuetify.breakpoint.name === 'xs' ? '330' : '0'"
                menu-wrapper
                no-title
                offset-y
                picker-color="secondary"
                :rules="[rules.date, rules.startBeforeEnd, rules.required]"
                single-line
                :textFieldClass="'mt-2'"
                v-model="hoursSearch[field.value]"/>
            </div>
        </th>
        </template>
        <template v-slot:item.session_interval="{ item }">
          <span>{{ item.time }}</span>
        </template>
        <template v-for="field in fieldsActiveSessions" v-slot:[`item.${field}`]="props">
          <div v-if="props.item[field] && (field === 'mobile' || field === 'home' || field === 'direct')">
            {{ props.item[field].replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}
          </div>
          <div v-else>{{ props.item[field] }}</div>
        </template>
      </v-data-table>
    </v-form>

    <ErrorDialog ref="errorDialog"/>

  </v-card>

</template>

<script>
  import ErrorDialog from "@/components/ErrorDialog";
  import InformationDialogIcon from '@/components/InformationDialogIcon';
  import moment from "moment";
  import { SessionsAPIService } from '@/servicehandlers/SessionsAPIService';
  import { UsersAPIService } from '@/servicehandlers/UsersAPIService';

  const sessionsAPIService = new SessionsAPIService();
  const usersAPIService = new UsersAPIService();

  export default {
    components: { ErrorDialog, InformationDialogIcon },
    props: ['items'],

    data() {
      return {
        dateFormat: 'DD MMM YYYY',
        dateFormatLooselyValid: 'D MMM YYYY',// for DatePickerFormatted.vue to accept and add leading zeros
        dropdownItems: {
          types: ['Any', 'Manual Logout', 'Automatic Logout', 'Vacation', 'Holiday', 'Floater', 'Sick Leave', 'Jury Duty',
            'Unpaid Leave', 'Penalty', 'Personal', 'Combined']
        },
        fieldsActiveSessions: ['mobile', 'home', 'direct', 'ext'],
        get: 'Time Entries',// default switch set to 'Time Entries'
        gettingActiveSessions: false,
        headerExtra1: [{value: 'extra1', text: '', width: "0", sortable: false },],// add space to larger screens...
        headerExtra2: [{value: 'extra2', text: '', width: "0", sortable: false },],// ...looks better than padding
        headersActiveSessions: [
          {value: 'username', text: 'Username',width: "180px", sortable: true, list: 'usernames' },
          {value: 'mobile',   text: 'Mobile',  width: "180px", sortable: false,align: 'start' },
          {value: 'home',     text: 'Home',    width: "180px", sortable: false,align: 'start' },
          {value: 'direct',   text: 'Direct',  width: "180px", sortable: true, align: 'start', list: 'types' },
          {value: 'ext',      text: 'Ext.',    width: "100px", sortable: true, align: 'right', sort: this.sortHours },
        ],
        headersSmallScreen: [
          {value: 'username',       text: 'Username', width: "100px", sortable: true, list: 'usernames' },
          {value: 'formatted_login',text: 'Start',    width: "210px", sortable: false,align: 'start' },
          {value: 'logout',         text: 'End',      width: "210px", sortable: false,align: 'start' },
          {value: 'type',           text: 'Type',     width: "155px", sortable: true, align: 'start', list: 'types' },
          {value: 'time',           text: 'Totals',   width: "155px", sortable: true, align: 'right', sort: this.sortHours },
        ],
        hoursSearch: {},
        list: [],
        listLength: '',
        loading: false,
        noDataText: 'Fill fields and click LOG, DAILY, or PERIOD',
        refresh: false,
        refreshButtons: false,
        rules: {
          date: (x) => (!x || moment(x, 'D MMM YYYY',true).isValid()
            || moment(x, 'DD MMM YYYY',true).isValid()) || 'Must be D MMM YYYY',
          required: (x) => !!x || 'Field is required',
          startBeforeEnd: (x) => (!x || this.startBeforeEnd) || 'End date before Start',
        },
        search: '',
        today: moment().format('YYYY-MM-DD'),
        totType: undefined,
        username: '',
        usernames: [],
        valid: false,
      }
    },

    watch: {
      get() {// if getting new data, clear search field
        this.search = '';
      },

      hoursSearch: {
        handler() {// if an hoursSearch field changes...
          this.list = [];// ...clear old results
          this.$nextTick(() => {
            this.$refs.form.validate();// ...validate form so error messages will update
          });
        },
        deep: true,
      },

      periodMoreThanYear() {// if dates periodMoreThanYear changes, refresh which buttons are disabled
        this.refreshButtons = !this.refreshButtons;
      },
    },

    computed: {
      headers() {
        // on larger screens add more space to table sides by adding extra headers (columns) - looks better than padding
        if (this.gettingActiveSessions) {
          return ['xs','sm'].includes(this.$vuetify.breakpoint.name)
              ? this.headersActiveSessions : [...this.headerExtra1, ...this.headersActiveSessions, ...this.headerExtra2];
        } else {
          return ['xs','sm'].includes(this.$vuetify.breakpoint.name)
            ? this.headersSmallScreen : [...this.headerExtra1, ...this.headersSmallScreen, ...this.headerExtra2];
        }
      },

      periodMoreThanYear() {// is the difference between start date and end date more than 1 year?
        if (!this.hoursSearch.formatted_login || !this.hoursSearch.logout) return false;
        let logout = this.hoursSearch.logout;
        let formatted_login = this.hoursSearch.formatted_login;
        let yearsBetweenDates = moment(logout).diff(moment(formatted_login), 'years', true);
        return (yearsBetweenDates > 1);
      },

      startBeforeEnd() {// is the start date the sameOrBefore the end date?
        if (!this.hoursSearch.formatted_login || !this.hoursSearch.logout) return true;
        return moment(this.hoursSearch.formatted_login).isSameOrBefore(moment(this.hoursSearch.logout));
      },
    },

    created() {
      this.username = this.$store.getters.username;
      if (this.$_can('hours-override')) usersAPIService.getActiveUsers(this.$router).then((users) => {// dropdown
        this.dropdownItems['usernames'] = [];
        for (let user of users) this.dropdownItems['usernames'].push(user.username);
        this.dropdownItems['usernames'].sort();
        this.dropdownItems['usernames'].unshift('All');// only for "Period" - lists usernames and hours for that period
        this.refresh = !this.refresh;
      }).catch((e) => this.$refs.errorDialog.showError({ forDevelopers: e }));

      // initialize hoursSearch fields to default values:
      let today = moment().format('DD MMM YYYY');
      this.hoursSearch = {
        username: this.username,
        formatted_login: today,
        logout: today,
        type: 'Any',
      }
    },

    methods: {
      formatTime(interval) {// need this because backend only formats time for the Period data, not Log or Daily
        let days = interval.days ? interval.days.toString() : '';
        let hours = interval.hours ? interval.hours.toString().padStart(2, '0') : '00';
        let minutes = interval.minutes ? interval.minutes.toString().padStart(2, '0') : '00';
        let seconds = interval.seconds ? interval.seconds.toString().padStart(2, '0') : '00';
        let time = `${days}${days.length ? 'd ' : ''}${hours}:${minutes}:${seconds}`;
        return time;
      },

      getActiveSessions() {
        sessionsAPIService.getActiveSessions(this.$router).then(data => {
          data.unshift({"username": data.length + " ACTIVE SESSIONS: ","user_id": 0 });
          this.list = data;
          this.listLength = '';
          this.gettingActiveSessions = true;
          if (data.length === 0) this.noDataText = 'Notice: no sessions found.';
        }).catch((e) => {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get active sessions.",
            forDevelopers: e
          })
        });
      },

      getHours(totType) {
        this.list = [];
        this.loading = true;
        let h = this._.cloneDeep(this.hoursSearch);// separate copy of hoursSearch, so setting username = '' won't show
        if (!h.username || h.username === 'All') h.username = '';// send '' to get period hours for All users
        usersAPIService.getHours(totType, h.username, h.type, h.formatted_login, h.logout, this.$router).then(data => {
          this.gettingActiveSessions = false;
          if ((data.logs && !data.logs.length) || (totType !== 'Log' && !data.length)) {// if data is empty, then update noData message
            this.noDataText = 'Notice: no sessions found.'
          } else {
            // data is returned in the following formats:
            // 'Log' {"logs":[],"sum":null,"core":null} OR {"logs":[{},{},{}...],"sum":"3460:26","core":"1993:24"}
            // 'Daily' [] OR [{"username":"larry","_hours":{"hours":3460,"minutes":26,"seconds":33},"_formatted":"3460:26:33"},{similar},{},...]
            // 'Period' [] OR [{"username":"larry","_hours":{"hours":3460,"minutes":26,"seconds":33},"_formatted":"3460:26:33"}]
            this.list = data.logs ? data.logs : data;
            this.listLength = this.list.length;
            if (totType === 'Log') {
              this.list.core = data.core;
              this.list.sum = data.sum;
              for (let entry of data.logs) entry.time = this.formatTime(entry.session_interval);
              for (let entry of data.logs) entry.logout = entry.formatted_logout;
            }
            if (totType === 'Daily' || totType === 'Period') {
              for (let entry of data) {
                entry.formatted_login = entry._login;
                entry.time = this.formatTime(entry._hours);
              }
            }
            this.refresh = !this.refresh;
          }
          this.loading = false;
          window.scrollTo(0, document.body.scrollHeight);
        }).catch((e) => {
          this.loading = false;
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get users hours.",
            forDevelopers: e
          })
        });
      },

      sortHours(a, b) {
        return parseInt(a.replace(/:/g,'')) > parseInt(b.replace(/:/g,''));
      },
    },

  }
</script>

<style>
  .box {/* keep box-shadow for disabled buttons so they line up evenly with non-disabled button */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }
  .nopad * {
    padding: 0 !important;
  }
  .v-data-table.med-table-rows td
  {
    height: 24px !important;
  }
  .down .v-text-field .v-input__control {
    font-weight: lighter;
    margin-left: 7px;
    margin-bottom: -20px;
  }
</style>
