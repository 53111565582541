import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.css';
import draggable from 'vuedraggable';
import ButtonTip from "./components/ButtonTip";
import DatePickerFormatted from "./components/DatePickerFormatted";
import ErrorDialog from "./components/ErrorDialog";
import InformationDialogIcon from "./components/InformationDialogIcon";
import SearchInput from "./components/SearchInput";
import SmartInput from "./components/SmartInput";
import Snack from "./components/Snack";
import JsonExcel from 'vue-json-excel';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import VueCookies from 'vue-cookies';
import Sticky from 'vue-sticky-directive';
import { store } from './store';
import { router } from './router';
import App from './App.vue';
import '@mdi/font/css/materialdesignicons.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VueTheMask from 'vue-the-mask';
import money from 'v-money'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import underscore from 'vue-underscore';
import 'typeface-roboto/index.css';
import { ListsAPIService } from "@/servicehandlers/ListsAPIService";
const listsAPIService = new ListsAPIService();

// eslint-disable-next-line no-console
console.debug({
  "BRANCH": process.env.VUE_APP_BRANCH,
  "COMMITHASH": process.env.VUE_APP_COMMITHASH,
  "PACKAGE_VERSION": process.env.VUE_APP_PACKAGE_VERSION,
  "VERSION": process.env.VUE_APP_VERSION
});

Vue.component('ButtonTip', ButtonTip);
Vue.component('DatePickerFormatted', DatePickerFormatted);
Vue.component('ErrorDialog', ErrorDialog);
Vue.component('InformationDialogIcon', InformationDialogIcon);
Vue.component('SearchInput', SearchInput);
Vue.component('SmartInput', SmartInput);
Vue.component('Snack', Snack);
Vue.component('multiselect', Multiselect);
Vue.component('downloadExcel', JsonExcel);
Vue.use(CKEditor);
Vue.use(Sticky);
Vue.use(underscore);
Vue.use(Vuetify);
Vue.use(VueLodash, {  lodash: lodash });
Vue.use(VueCookies);
Vue.use(moment);
Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.use(Datetime);
Vue.use(draggable);
Vue.use(VueTheMask);
// register directive v-money and component <money>
Vue.use(money, {
  precision: 2,
  decimal: '.',
  thousands: ',',
  prefix: '$',
  masked: false,
});

Vue.mixin({
  computed: {// sideMenu affects sticky-offsets and data-tables throughout
    $_cssVars() { return this.$_sideMenu === true ? { '--offset': '0px' } : { '--offset': '48px' } },
    $_offset: {
      get() { return this.$_sideMenu === true ? { top: 0 } : { top: 48 } },
      set(value) { this.value = value }
    },
    $_lists() {
      let lists = this.$store.getters.lists;
      return !lists ? null : lists;
    },
    $_scopes() {
      let scopes = this.$store.getters.scopes;
      return scopes;
    },
    $_sideMenu() { return this.$store.getters.sideMenu },
    $_snack() { return this.$store.getters.snack },// for easy use of snack message throughout
  },
  methods: {
    $_can(scope) {
      const userScopes = this.$store.getters.scopes.map(({ name }) => name);
      return userScopes.includes(scope);
    },
    $_canAll(scopes) {
      const userScopes = this.$store.getters.scopes.map(({ name }) => name);
      return scopes.every(scope => userScopes.includes(scope));
    },
    $_canAny(scopes) {
      const userScopes = this.$store.getters.scopes.map(({ name }) => name);
      return scopes.some(scope => userScopes.includes(scope));
    },
    $_editFirm(firm) {// this popup is called in FirmsSearch.vue, ViewFirm.vue, and EditFirm.vue
      if (!firm.fid) return;
      let baseUrl = window.location.origin;
      window.open(baseUrl +  '/editFirm/' +  firm.fid, '_blank',
        'resizeable=yes, width=' + (1000) + ', left=' + (500) + ', top=' + (22) + ', height=' + (900));
    },
    $_editPerson(person) {// this popup is called in PeopleSearch.vue, and FirmMembersTable.vue
      if (!person.plid) return;
      let baseUrl = window.location.origin;
      window.open(baseUrl +  '/editPerson/' + person.plid, '_blank',
        'resizeable=yes, width=' + (980)
        + ', left=' + (500)
        + ', top=' + (22)
        + ', height=' + (600));
    },
    $_eq(obj1,obj2) {// return 1='1', 0='0', not 0='0.00' (input fields change number to string, so compare as strings)
      const cmpStr2Num = (v1, v2) => {
        if ((v1 === null && v2 === '') || (v2 === null && v1 === '')) return true;// consider null and '' equal
        if ((typeof v1 === 'number' && typeof v2 === 'string') || (typeof v2 === 'number' && typeof v1 === 'string')) {
          return v1.toString() === v2.toString();
        }
      }
      return _.isEqualWith(obj1, obj2, cmpStr2Num);
    },
    $_eq(obj1,obj2) {// return 1='1', 0='0', not 0='0.00' (input fields change number to string, so compare as strings)
      const cmpStr2Num = (v1, v2) => {
        if ((v1 === null && v2 === '') || (v2 === null && v1 === '')) return true;// consider null and '' equal
        if ((typeof v1 === 'number' && typeof v2 === 'string') || (typeof v2 === 'number' && typeof v1 === 'string')) {
          return v1.toString() === v2.toString();
        }
      }
      return _.isEqualWith(obj1, obj2, cmpStr2Num);
    },
    $_getLists() {// used multiple places, so get here and put in store for getter in computed $_lists
      let listItems = [];
      let lists = ['name','carrier','ctype','ptype','dtype','ftype','frmtype','ltype','cancat','deduc','is_new','ledgeracct','ledgertype',
        'limits','plt_code','def_code','err_code','aop_code','clmstatus','role','states'];
      let count = 1;//count how many lists to get, to set state after last list
      for (let list of lists) {
        let sort = ['name','carrier','ftype','ptype','ledgeracct','ledgertype'].includes(list) ? 0
          : ['aop_code'].includes(list) ? [1,2,3,4,5,6,7,8,9,10] : '';
        let nameonly = !['aop_code', 'def_code', 'err_code','plt_code'].includes(list);
        let longonly = ['aop_code'].includes(list);
        let sortTable = ['aop_code'].includes(list) ? 'long' : 'short';
        listsAPIService.getList(list,sort,nameonly,longonly,'ASC',sortTable, true, this.$router).then((data) => {
          if (data[0].text === '') data.splice(0, 1);// remove 1st row from dropdown if blank
          listItems[list] = data;
          // console.debug(list,data)
          if (lists.length === count) {// if done getting all the getList API lists, now get status list - different API
            listsAPIService.getPolicyStatusList(this.$router).then((statuses) => {
              const status = [];// set the headers
              for (let s of  statuses) if (s.short !== '') {
                s.short.includes('-') ? status.push({text: s.short, header: s.short}) : status.push({text: s.short});
              }
              listItems.pstatus = status;
              this.$store.dispatch('ADD_LISTS', listItems);
            });
          }
          else count++;
        });
      }
    },
    $_hasExtension(name) {// used multiple places to check if file has file extension, and it's at least one character
      return name.includes('.') && name.split('.').pop().length;
    },
    $_prettyBytes(bytes) {// pretty format bytes w/base 10, rounding, & displaying 1 decimal digit, ex:22099 --> 22.1 KB
      let sizes = [' bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
      for (let i = 1; i < sizes.length; i++) {
        if (bytes < Math.pow(1000, i))
          return (Math.round((bytes / Math.pow(1000, i - 1)) * 10) / 10) + sizes[i - 1];
      }
      return bytes;
    },
  }
});

Vue.directive('can', function (el, bindings, vnode) { // Custom roles directive
  const userScopes = vnode.context.$store.getters.scopes.map(({ name }) => name);
  const scopes = bindings.value;

  let can = false;
  if (!bindings.arg) {
    can = userScopes.includes(scopes);
  } else if (bindings.arg === 'all') {
    can = scopes.every(scope => userScopes.includes(scope));
  } else if (bindings.arg === 'any') {
    can = scopes.some(scope => userScopes.includes(scope));
  }

  if (can) return;

  commentNode(el, vnode);
});

function commentNode(el, vnode) { // Replaces a DOM element with a comment - used in v-can directive
  const comment = document.createComment(' ');

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  });

  vnode.text = ' ';
  vnode.elm = comment;
  vnode.isComment = true;
  vnode.context = undefined;
  vnode.tag = undefined;
  vnode.data.directives = undefined;

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment;
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el);
  }
}

const opts = {
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: { // these are from quoting-app-ui
        primary: '#022640', // dominion blue
        secondary: '#9CBE7E', // green
        accent: '#147a76',
        accent1: '#808080', // gray, for checkboxes and some buttons
        error: '#b71c1c',
        background: '#fafafa',
      },
      dark: {},
    },
  },
};

new Vue({
  router,
  data: function () {
    return {
      deploymentEnv: process.env.VUE_APP_DEPLOYMENT_ENV ? `(${process.env.VUE_APP_DEPLOYMENT_ENV})` : '',
    };
  },
  store,
  draggable,
  el: '#app',
  render: h => h(App),
  vuetify: new Vuetify(opts)
}).$mount('#app');

