<template>
  <div>
    <v-card ref="infoBox" max-height="100px" class="scroll">
      <div v-for="note in notesActiveStatus" style="cursor: pointer" :key="note.re + note.nid">
        <v-row style="background-color: lightgrey" class="py-3 mx-0 caption">
          <EditNoteOpen class="mt-n2 mb-n2" @refresh="refresh"
                        v-bind="{ editPopup: note.editRight.note, note: note, username, ismall: true }"/>
          &nbsp {{ moment(note.cstamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A')}} &nbsp {{note.staff}}  &nbsp
          <v-spacer/>
         {{note.re}} &nbsp &nbsp
        </v-row>
        <div style="background-color: whitesmoke" class="px-2 pb-4 caption" v-html="note.htmlNote"/>
      </div>
    </v-card>
    <v-card class="pt-1 mt-n2 elevation-12 rounded-0">
      <v-card-title class="rounded-0 subtitle-1 py-0 pb-sm-0">
        <button-tip btnclass="ml-n1 mt-n2" @click="refresh()" elevation="5" fab icolor="black"
                    iname="mdi-update" tip="Refresh Notes" xsmall/>
        <button-tip btnclass="mt-n2 ml-1 mr-4"
                    @click="status = 'All', nextJobNid = 0, search[firm.fid] = '', firstSearch = false, inlineEdit = false, refresh()"
                    color="secondary" elevation="5" fab icolor="white"
                    iname="mdi-arrow-down-circle-outline" tip="Refresh & See ALL Notes" xsmall/>
        <v-tooltip right style="z-index: 99"
                   :disabled="$store.getters.userPreferences.noTooltips">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-menu open-on-hover offset-y z-index="99">
                <template v-slot:activator="{ on }">
                  <v-btn x-small fab color="secondary" v-on="on" style="margin-top: -10px"
                         class="ml-n3" elevation="4"><v-icon>mdi-autorenew</v-icon>
                  </v-btn>
                  <span> {{ status }}</span>
                </template>
                <v-list>
                  <v-list-item
                      v-for="mode in modes"
                      :key="mode"
                      @click="status = mode, nextJobNid = 0, search[firm.fid] = '', firstSearch = false">
                    <v-list-item-title color="primary">{{ mode }} Notes
                      <div class="caption" v-if="mode === 'Open'"> - Current Batch</div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>
           See Active, Status or Open Notes</span>
        </v-tooltip>
        <v-spacer/>
        <v-row class="mx-2 pt-0 mt-n3 mb-n2">
          <v-tooltip left nudge-bottom="30">
            <template v-slot:activator="{ on }">
              <div class="leftLabel" v-on="on" v-if="rightsArray.includes('note-edit')">
                <v-switch dense x-small
                          color="secondary"
                          v-model="inlineEdit"
                          label="Edit"/>
              </div>
              <!--                adds spacing to replace switch when not note-edit right-->
              <div v-else style="margin: 36px"/>
            </template>
            <span>(This switch slow if many notes) ON-inline edit allowed fields below
              & OFF-faster notes load</span>
          </v-tooltip>
        </v-row>
        <v-row class="pb-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="lowerLabel" v-on="on">
                <v-select v-model="batch" :key="notesCount" dense single-line
                          v-if="status !== 'Web'"
                          :hint="`Notes Batch ${batch}/${batchCount}`" persistent-hint
                          @change="search[firm.fid] = '', nextJobNid = 0"
                          :label="`Notes Batch ${batch}`" item-value="number"
                          class="borderless lower px-3"
                          style="width:180px;margin-top:-2px" :items="batchTitles">
                  <template v-slot:selection="{ item }">
                    {{ item.title }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.title }}
                  </template>
                </v-select>
              </div>
            </template>
            <span>Up to {{ batchSize }} notes are loaded at a time.  If there is more than
              one batch, select desired batch of {{ batchSize }} notes above.</span>
          </v-tooltip>
          <v-text-field append-icon="mdi-magnify" class="pl-2 mt-n1 borderless" clearable @click="nextJobNid = 0"
                        @click:clear="nextJobNid = 0, search[firm.fid] = ''" dense hint="Search Notes"
                        :key="nextJobNid + firm.fid" label="Search Notes" persistent-hint single-line
                        style="width:20vw;margin-top:-7px" v-model="search[firm.fid]"/>
        <EditNoteOpen @refresh="refresh" v-bind="{ createNew: true, firm, username }" v-can="'note-edit'"/>
        </v-row>
      </v-card-title>
    </v-card>

    <div>
      <v-data-table
          :height="noteTableHeight"
          class="elevation-6 rows pb-6"
          @click:row="rowClick"
          :dense="dense"
          :expanded.sync="expanded"
          fixed-header
          :headers="colHeaders"
          hide-default-footer
          id="table"
          item-key="nid"
          :items="status === 'Open' ? expanded : notes"
          :items-per-page="1000"
          :loading="loading"
          loading-text="Data loading... Please wait..."
          :mobile-breakpoint="0"
          multi-sort
          :search="search[firm.fid]"
          single-select>
        <template v-slot:expanded-item="{ headers, item }">
          <note-expansion :colspan="headers.length" :files="item.files || []" :key="refreshExpansion"
                          :note="item.formattedNote ? item.formattedNote : item.note" :tasks="item.tasks"
                          :type="item.type" :nid="item.nid" :width="'60%'"/>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-lazy>
            <v-row>
              <button-tip @click="handleExpansion(item)" icon iname="mdi-arrow-expand-vertical" tip="Expand Note"/>
              <button-tip btnclass="mx-n2" @click="item.type === 'web' ? callNotesApiForWebins(item) : getTranLog(item)"
                          icon iname="mdi-dots-vertical" :tip="item.type === 'web' ? 'See Webins' : 'See Tranlog'" v-if="item.type !== 'web in'"/>
              <EditNoteOpen class="ml-1" :key="item.editRight.note" @refresh="refresh"
                            v-bind="{ copy: true, editDialog: item.editRight.note, editPopup: item.editRight.note, note: item, username }"/>
              <button-tip btnclass="mx-n1" @click="confirmDeleteNote(item)" icon
                          iname="mdi-trash-can-outline" tip="Delete Note" v-if="groups.includes('admin')"/>
            </v-row>
          </v-lazy>
        </template>

        <template v-slot:item.cstamp="{ item }">
          <v-edit-dialog :return-value="item.cstamp"
                         v-if="inlineEdit && item.editRight.cstamp">
            <div @click="saveNoteCacheDate(item)">
              <datetime type="datetime" v-model="item.cstamp"
                        :input-style="{width: '150px', color: (!!nextJobNid && item.nid.toString() === search[firm.fid]  ? 'red' : 'black' )}"
                        :week-start="7" format="dd MMM yyyy hh:mm a " class="theme-dominionBlue point"
                        use12-hour auto @close="saveDates(item)"
              />
            </div>
          </v-edit-dialog>
          <div v-else style="cursor: pointer"
               :style="{color: (!!nextJobNid && item.nid.toString() === search[firm.fid]  ? 'red' : 'black' )}">
            {{ moment(item.cstamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A')}}
          </div>
        </template>
        <template v-slot:item.type="{ item }" v-if="inlineEdit">
          <v-edit-dialog @close="refreshSelect = !refreshSelect" v-if="editDialog">
            <span @click="inlineEditClicked(item, 'type')" >{{ item.type }}</span>
            <template v-slot:input v-if="item.editRight.other">
              <v-autocomplete class="px-2 mb-6" :hint="inlineEditHint" @input="inlineEditInput(item, 'type')"
                              item-text="text" item-value="text" :items="typeItems" item-disabled="disabled" :key="refreshSelect"
                              @keydown.enter="inlineEditEnterPress(item,'type')" persistent-hint ref="autocomplete" v-model="item.type"/>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.status="{ item }" v-if="inlineEdit">
          <v-edit-dialog @close="refreshSelect = !refreshSelect" v-if="editDialog">
            <span @click="inlineEditClicked(item, 'status')" >{{ item.status }}</span>
            <template v-slot:input v-if="item.editRight.statusRe">
              <v-autocomplete class="px-2 mb-6" :hint="inlineEditHint" @input="inlineEditInput(item, 'status')"
                              item-text="text" item-value="text"  :items="statusItems" :key="refreshSelect"
                              @keydown.enter="inlineEditEnterPress(item,'status')" persistent-hint ref="autocomplete" v-model="item.status"/>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.re="{ item }">
          <!--    check inlineEdit in div below so v-html will show and change combobox to textfield to work with v-edit-dialog -->
          <div v-if="inlineEdit">
            <v-edit-dialog :return-value="item.re" @save="saveNote(item)">
              <div v-html="item.re"></div>
              <template v-slot:input v-if="item.editRight.statusRe">
                <v-text-field v-model="item.re" persistent-hint hint="ENTER to Save" class="px-2 mb-6"/>
              </template>
            </v-edit-dialog>
          </div>
          <div v-else v-html="item.re"></div>
        </template>
        <template v-slot:item.staff="{ item }" v-if="inlineEdit">
          <v-edit-dialog @close="refreshSelect = !refreshSelect" v-if="editDialog">
            <span @click="inlineEditClicked(item, 'staff')" >{{ item.staff }}</span>
            <template v-slot:input v-if="item.editRight.staff">
              <v-autocomplete class="px-2 mb-6" :hint="inlineEditHint" @input="inlineEditInput(item,'staff')"
                              :items="forStaff" item-text='username' item-value='username' :key="refreshSelect"
                              @keydown.enter="inlineEditEnterPress(item,'staff')" persistent-hint ref="autocomplete" v-model="item.staff"/>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </div>
    <ActionDialog :value="confirmDeleteDialog" width="700" divider confirm-text="Delete Note"
                  title="Delete Note" close-text="Cancel" confirm-color="error"
                  @confirm="deleteNote" @close="confirmDeleteDialog = false">
      <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
        Are you sure you want to <strong>delete</strong> this note?
        <br><br>re: {{noteToDelete.re}}<br><br>
        This deletes it from the database, with no way to recover. Click "DELETE" below to proceed with deletion.
      </v-alert>
    </ActionDialog>
    <v-snackbar app color="secondary" v-model="saveSuccess" style="top: 10px;" top>
      The note change was saved.
      <v-btn class="ml-12 pl-12" color="white" text @click="saveSuccess = false">Close</v-btn>
    </v-snackbar>

    <TranLog v-if="showTranLog" :nid="tranLogNid" :value="showTranLog" ref="tranLog"/>

    <ErrorDialog ref="errorDialog"/>

  </div>
</template>

<script>
  import ActionDialog from '../../ActionDialog';
  import EditNoteOpen from '@/components/search/firms/firmNotesTable/EditNoteOpen';
  import goTo from 'vuetify/es5/services/goto';
  import ListViewer from '@/components/ListViewer';
  import moment from 'moment';
  import NoteExpansion from './firmNotesTable/NoteExpansion';
  import TranLog from '@/components/search/firms/TranLog';
  import {_} from 'vue-underscore';
  import { Datetime } from 'vue-datetime';
  import { FileobjectsAPIService } from '@/servicehandlers/FileobjectsAPIService';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { GroupsAPIService } from '@/servicehandlers/GroupsAPIService';
  import { ListsAPIService } from '../../../servicehandlers/ListsAPIService';
  import { NotesAPIService } from '@/servicehandlers/NotesAPIService';
  import { UsersAPIService } from '@/servicehandlers/UsersAPIService';

  const apiService = new FirmsAPIService();
  const file_objects = new FileobjectsAPIService();
  const groupsAPIService = new GroupsAPIService();
  const listsAPIService = new ListsAPIService();
  const notesAPIService = new NotesAPIService();
  const usersAPIService = new UsersAPIService();

  export default {
    name: "FirmNotesTable",
    components: { ActionDialog, Datetime, EditNoteOpen, ListViewer, NoteExpansion, TranLog },
    props: {
      nextJobNidProp: Number,
      nextJobFidProp: Number,
      statusProp: String,
      updateNoteTableHeight: Boolean,
      firm: Object,
      firmArray: Array,
      rightsArray: Array,
      notPopup: Boolean,
      noNote: Boolean,
      default() {
        return {};
      }
    },
    data() {
      return {
        editorConfig: {
          toolbar: { shouldNotGroupWhenFull: true },
          placeholder: 'Type some text...'
        },
        msg: 'initial',
        resetEditor: false,

        //--------- Toggle Variables ---------//

        confirmDeleteDialog: false,
        confirmed: false,
        dense: true, // notes table density
        editDialog: true,
        firstSearch: true,
        fromStatus: false,
        inlineEdit: false,
        inlineEditEnterPressed: false,// inline enter press also triggers SELECT input method, this flag will exit that
        loading: false,
        newSearch: true,
        refreshExpansion: false,
        refreshSelect: false,
        saveSuccess: false,
        showCreateNote: false,
        showEditEmail: false,
        showEditNote: false,
        showSelect: false,
        showTranLog: false,
        sortNotesDesc: true,
        valid: false,
        value: false,

        //--------- Data Variables ---------//

        note: {},
        username: '',
        noteToDelete: {},
        itemS: {},
        itemE: {},
        firmPeople: [],
        forStaff: [], // inline edit of note.staff dropdown select
        groups: [],
        inlineEditHint: 'ENTER if only 1 option, else SELECT to Save',
        staffArray: [],
        typeItems: [],
        statusItems: [],
        errors: [],
        search: [],
        modes: ['Active', 'Status', 'Open'],
        headers: [
          {value: 'cstamp',text: 'Date', width: "180px" },
          {value: 're',    text: 'Re',   width: "300px" },
          {value: 'staff', text: 'For',  width: "100px" },
          {value: 'note',  text: 'Note', width: "700px" },
        ],
        colHeaders: [
          {value: 'actions', text: ' Actions', width: "220px", sortable: false},
          {value: 'cstamp', text: ' Date', width: "190px", sortable: true},
          {value: 'type', text: 'Type', width: "100px", sortable: true},
          {value: 'status', text: 'Status', width: "90px", sortable: true},
          {value: 're', text: 'Re', width: "425px", sortable: true},
          {value: 'staff', text: 'For', width: "100px", sortable: true},
          {value: 'closer', text: 'Last', width: "100px", sortable: true},
          {value: 'opener', text: 'By', width: "100px", sortable: true},
          {value: 'nid', text: 'Note Id', width: "100px", sortable: true},
        ],
        savedOpen: [],
        tranLogNid: '',
        expanded: [],
        notes: [],
        notesActiveStatus: [],
        noteData: {},
        noteId: 0,
        originalField: '',
        savedWebNote: {},
        status: this.statusProp,
        nextJobNid: this.nextJobNidProp,
        nextJobFid: this.nextJobFidProp,
        notesCount: 0,
        batchChanged: false,
        batchSize: 1000,
        batch: 1,
        subBatch: 1,
        subBatchSize: 100,
        noteOptions: {
          page: 1,
          itemsPerPage: 50,
          sortBy: [null],
          sortDesc: [false],
        },
        styl: '<style> .styl table ' +
          '{border-collapse: collapse; margin: 0 auto; width: auto !important; } ' +
          '.styl table, .styl th, .styl td, .styl tr' +
          '{border: 1px solid black !important;} .styl td {padding: 1em;}</style><div class="styl">',
      noteTableHeight: '',
      refreshTime: 0
    }
  },

    watch: {
      batch () {
        this.subBatch = 1;
        this.callNotesApi();
      },

      notes (newVal) {
        if (newVal.length > 0) {
          // console.timeEnd('notes loaded');
          this.$nextTick(() => {
            // console.timeEnd('table rendered')
          });
        }
      },

      updateNoteTableHeight () {
        this.updateTableHeight();
      },

      $_sideMenu () {
        this.updateTableHeight();
      },

      status () {
        if (this.status === 'Web' || this.status === 'All') return;
        if (!!this.noNote) this.firstSearch = false;
        if (this.firstSearch){
          this.firstSearch = false;
        } else {
          if (this.status !== 'Open'){
            this.refresh();
          }
        }
        this.expandByStatus()
      },
    },

    computed: {
      batchOffset() {
        return (this.batch - 1) * (this.batchSize / this.subBatchSize);
      },

      batchCount() {
        return Math.trunc(this.notesCount / this.batchSize) + 1;
      },

      batchTitles() {
        let batchTitles = [];
        for (let i = 0; i < this.batchCount; i++) {
          const batchOffset = this.batchSize * i;
          const startNumber = batchOffset + 1;
          const endNumber = (batchOffset + this.batchSize) < this.notesCount ? (batchOffset + this.batchSize) : this.notesCount;
          let batchTitle = {
            title: `${startNumber}-${endNumber}`,
            number: i + 1
          };
          batchTitles.push(batchTitle);
        }
        return batchTitles
      },

      subBatchCount() {
        if (this.batch !== this.batchCount) {
          return this.batchSize / this.subBatchSize;
        }
        let notesRemaining = this.notesCount - ((this.batchCount - 1) * this.batchSize);
        if (notesRemaining % this.subBatchSize === 0) return notesRemaining / this.subBatchSize;
        return Math.trunc(notesRemaining / this.subBatchSize) + 1;
      }
    },

    created: function () {
      // console.time('table rendered');
      // console.time('notes loaded');
      this.editorConfig.toolbar.shouldNotGroupWhenFull = true;
    },

    mounted () {//get data from store, and apis
      if (!this.noNote && this.rightsArray.includes('note-edit')) this.inlineEdit = true;
      if (this.firm.nid) this.search[this.firm.fid] = this.noNote ? '' : this.firm.nid.toString();
      this.noteData = this.$store.getters.noteData;
      if (!!this.notPopup) {this.status = !!this.noteData.status ? this.noteData.status
        : (this.nextJobFid !== 0 ? 'Active' : 'All')}
      this.expanded = this.noteData.expanded ? this.noteData.expanded : [];
      this.savedOpen = this.noteData.savedOpen ? this.noteData.savedOpen : [];
      this.username = this.$store.getters.username;
      this.userId = this.$store.getters.userId;
      this.groups = this.$store.getters.groups;

      usersAPIService.getUserACLS(this.userId, this.$router).then((data) => {
        if (data[0]) {
          for (let i = 0; i < data[0].allowed_users.length; i += 1) {
            this.staffArray.push(data[0].allowed_users[i].username);
          }
          this.staffArray.push(this.username);
        }
      }).catch((e) => {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get users ACLS.",
          forDevelopers: e
        })
      });
      usersAPIService.getActiveUsers(this.$router).then((data) => {
        if (data) this.forStaff = data;
      }).catch((e) => {
        this.$refs.errorDialog.showError({
          errorMessage: "Failed to get Active Users.",
          forDevelopers: e
        })
      });
      this.callNotesAndStatusApi();

      //get Items for note editing w listname, sort, nameonly, longonly, sortorder
      listsAPIService.getList('nstatus', "1,2", true, false,
          'DESC', 'short', null, this.$router).then((data) => {
        if (data) this.statusItems = data;
        }).catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
      listsAPIService.getList('ntype',"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
          true, false, 'ASC', 'sort', null, this.$router).then((data) => {
        if (data) this.typeItems = data;
        if (!this.groups.includes('admin')) {// if user doesn't have admin override, disable some type options
          for (let type of this.typeItems) type.disabled = ['fax in', 'email in', 'web in'].includes(type.text);
        }
        }).catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
      listsAPIService.getList('re',
          "", false, true, 'ASC', 'short', null, this.$router).then((data) => {
        if (data) this.reItems = data;
        }).catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
    },

    methods: {
      editable(note, field) {
        const isUser = note.staff === this.username;

        // admin overrides most editing restrictions
        if (this.groups.includes('admin')) {
          if (['note', 'staff'].includes(field)) return true;// admin can edit staff & click edit note icons
          else return isUser;
        }

        const editable = this.$_can('note-edit') && !['done', 'cancel'].includes(note.status);
        let editableType = !['email in', 'fax in', 'web in'].includes(note.type);// default types not editable
        if (field === 'cstamp') editableType = ['status','memo','email out'].includes(note.type);// cstamp: only status/memo/emailout editable
        return field === 'note' || field === 'statusRe' ? editable && isUser
          : field === 'staff' ? editable && this.staffArray.includes(note.staff)
          : editable && isUser && editableType;// all other fields (cstamp, type)
      },

      editRightsSet(note) {
        // edit rights must be set ahead or editing can be aborted as values change
        note.editRight = {};
        note.editRight.note    = this.editable(note, 'note');// can edit whole note
        note.editRight.statusRe= this.editable(note, 'statusRe');// can edit status or re line
        note.editRight.staff   = this.editable(note, 'staff');// can edit staff
        note.editRight.cstamp  = this.editable(note, 'cstamp');// can edit cstamp
        note.editRight.other   = this.editable(note, 'other');// can edit all other fields
      },

      expandByStatus() {// determine which notes are expanded
        this.expanded = this._.uniqBy(this.expanded, 'nid');//remove any duplicates
        if (this.status === 'Status') {
          this.fromStatus = true;
          //first save current array of expanded notes
          this.savedOpen = this._.uniqBy(this.expanded, 'nid');
          //loop through notes and if status is 'Status' and note active, push note to expanded
          for (let i = 0; i < this.notes.length; i += 1) {
            if (this.notes[i].type === 'status' && this.notes[i].status !== 'done'
                && this.notes[i].status !== 'cancel') {
              this.getNoteFiles(this.notes[i]);// gets noteFiles
              this.expanded.push(this.notes[i]);// and expands the note
            }
          }
          this.expanded = this._.uniqBy(this.expanded, 'nid');//remove any duplicates
        } else if (this.status !== 'Status') {
          if (this.fromStatus) {// remove all status items from expanded that are not done/cancel
            for (let i = 0; i < this.expanded.length; i += 1) {
              if (this.expanded[i].status !== 'done' && this.expanded[i].status !== 'cancel' ) {
                this.expanded.splice(i, 1);
                i = i - 1;
              }
            }
            this.expanded = this._.unionWith(this.savedOpen, this.expanded, _.isEqual);
            this.savedOpen = [];
            this.fromStatus = false;
          } else {
            //if coming from status we want savedOpen and any newly expanded,
            // from anywhere else we only want expanded
            this.expanded = this._.uniqBy(this.expanded, 'nid');//remove any duplicates
          }
        }
      },

      handleExpansion(note) {
        let index = this.expanded.findIndex(x => x.nid === note.nid);
        if (index === -1) {
          this.expanded.push(note);
          if (!note.files) {
            this.getNoteFiles(note);
          }
        } else {
          this.expanded.splice(index, 1);
        }
      },


      //---------  inline edit note methods ---------//

      inlineEditClicked(note, field) {// handle inline edit CLICK
        if (note[field]) this.originalField = note[field];// this field can't be empty, so save val, in case user clears
        this.inlineEditEnterPressed = false;// set flag to false
      },

      inlineEditEnterPress(note, field) {// handle inline edit ENTER press
        this.inlineEditEnterPressed = true;
        if (!this.$refs.autocomplete.filteredItems[0] || this.$refs.autocomplete.filteredItems.length > 1) return;
        note[field] = field === 'staff' ? this.$refs.autocomplete.filteredItems[0].username
            : this.$refs.autocomplete.filteredItems[0].text;
        this.saveNote(note, field);
      },

      inlineEditInput(note, field) {// handle inline edit SELECT
        if (this.inlineEditEnterPressed) {//enter pressed? if so inlineEditEnterPress handles, so reset flag & return
          this.inlineEditEnterPressed = false;
          return;
        }
        if (!note[field]) {
          note[field] = this.originalField;
          return;
        }
        this.saveNote(note, field)
      },

      getNoteFiles(note) {
        notesAPIService.getNoteFiles(note.nid, this.$router).then((data) => {
          note.files = data;
          this.refreshExpansion = !this.refreshExpansion;
        }).catch((e) => {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get noteFiles.",
            forDevelopers: e
          });
        });
      },

      getTranLog(note) {
        this.tranLogNid = note.nid;
        this.showTranLog = true;
      },

      refresh() {
        this.firstSearch = false;// set flag so nextJob/note expansion is only toggled the first time, not on refresh
        this.subBatch = 1;
        this.refreshTime = Date.now();
        this.status === 'Web' ? this.callNotesApiForWebins(this.savedWebNote)
            : this.callNotesAndStatusApi();
      },

      rowClick: function (item, row) {
        row.select(true);
      },

      updateTableHeight() {
        this.$nextTick(() => {
          const interval = setTimeout(() => {
            let height = 0;
            if (this.$refs.infoBox) height = this.$refs.infoBox.$el.clientHeight;// get height of status note card
            let topOffset =  this.notPopup ? 200 : 136;
            if (this.$_sideMenu) topOffset -=  48;// need less header room for sideMenu
            this.noteTableHeight = 'calc(100vh - ' + (topOffset + height) + 'px)';
          }, 600)// delay .6 seconds to allow data to load
        });
      },


      //---------  save/delete note methods ---------//

      saveNoteCacheDate(note) {
        if (note.dateAlreadyCached) return;
        note.originalDate = note.cstamp;
        note.dateAlreadyCached = true;
      },

      saveDates(note) {
        if (note.cstamp === note.originalDate) return; // only save note.cstamp if it has changed
        this.saveNote(note, 'cstamp')
      },

      saveNote(note, field) {
        this.editDialog = false;// inline edit dialog doesn't close after SELECT, so force it to close
        this.$nextTick(() => this.editDialog = true);// pause, then reset so fields will show
        this.inlineEditEnterPressed = false;// reset flag for ENTER press
        let noteToSave = note;
        // if (field === 'cstamp' && note.cstamp === note.originalDate) return;// only save note.cstamp if it has changed
        if (field === 'staff') {
          if (note.staff === note.originalStaff) return;
          // set last/closer to originalStaff, add comment 'username took from originalStaff'
          noteToSave.closer = note.originalStaff;
          let date = moment(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A');
          let comment = `${date}  (${this.username}) -  `;
          if (note.originalStaff !== this.username) comment = comment +  `took from ${note.originalStaff}`;
          if (note.originalStaff !== this.username && note.staff !== this.username) comment = comment +  ` and `;
          if (note.staff !== this.username) comment = comment +  `gave to ${note.staff}`;
          noteToSave.tasks = note.tasks ? comment + '\n' + '\n' + note.tasks : comment;
        }
        return notesAPIService.updateNote( noteToSave.nid, noteToSave, this.$router).then(() => {
          this.saveSuccess = true;
          this.editRightsSet(noteToSave);// use new values in noteToSave to set edit rights
          note.dateAlreadyCached = false;
          note.originalStaff = noteToSave.staff;
        }).catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
      },

      confirmDeleteNote(item) {
        this.noteToDelete = item;
        this.confirmDeleteDialog = true;
      },

      deleteNote() {
        notesAPIService.deleteNote(this.noteToDelete.nid, this.$router).then((response) => {
          this.confirmDeleteDialog = false;
          // refresh notes table to show note is gone
          // TODO: Do we really need to refresh the table here? Can't we just filter the set we already have?
          this.refresh();
          }).catch((e) => {
            this.$refs.errorDialog.showError({
              forDevelopers: e
            })
          });
      },


      //---------  call note API methods ---------//

      callNotesAndStatusApi() {//get status notes and notes for main notes table
        let refreshTime = this.refreshTime;
        let searchParams = {
          page: this.batch,
          perPage: this.batchSize,
          searchcolumn: 'cstamp',
          sortorder: 'DESC',
          whereObjects: {
            fid: this.firm.fid,
            type: ['status'],
            status: ['archive', 'check', 'hold', 'low', 'normal', 'urgent', 'xtreme']
          }
        };
        this.loading = true;
        notesAPIService.searchNotes(searchParams, this.$router).then(activeStatusData => {
          if (refreshTime !== this.refreshTime) return;
          this.notesActiveStatus = activeStatusData.result;
          for (let note of this.notesActiveStatus) {
            this.editRightsSet(note);
            note.htmlNote = note.note.replace(/(?:\r\n|\r|\n)/g, '<br>');
          }
          this.updateTableHeight();
          this.callNotesApi();
        })
        .catch(e => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        })
      },

      callNotesApi() {//get notes for notes table
        let refreshTime = this.refreshTime;
        let searchParams = {
          page: this.subBatch + this.batchOffset,
          perPage: this.subBatchSize,
          searchcolumn: 'cstamp',
          sortorder: 'DESC',
          whereObjects: {
            fid: this.firm.fid
          }
        };

        const admin = this.groups.includes('admin');
        let types = ['web','web in','memo','msg in','msg out','tel in','tel out','fax in','fax out',
          'fax failed','ltr in','ltr out','email in','email out','email failed','appt','update',
          'status','retry','news','event','surplus','note'];

        if (['Active', 'All'].includes(this.status) && !admin) searchParams.whereObjects.type = types;
        if (['Active', 'All'].includes(this.status) &&  admin) searchParams.whereObjects.type = types.concat('admin');

        if (this.status === 'Active') searchParams.whereObjects.status = ['archive', 'check', 'hold', 'low', 'normal', 'urgent', 'xtreme'];
        if (this.status === 'Status') searchParams.whereObjects.type = ['status'];

        notesAPIService.searchNotes(searchParams, this.$router).then(notes => {
          this.loading = false;
          if (refreshTime !== this.refreshTime) return;
          this.notesCount = notes.count;

          let formattedNotes = notes.result.map(note => {
            note.files = null;
            this.editRightsSet(note);
            note.originalStaff = note.staff;
            if (!(note.type === 'web in' || note.type === 'web') && note.note) {
              note.formattedNote = note.note.replace(/(?:\r\n|\r|\n)/g, '<br>')
            }
            return note;
          });

          this.notes = this.subBatch === 1 ? formattedNotes : this.notes.concat(formattedNotes);
          // use handleExpansion to toggle expansion of the nextJob note, but only first time (firstSearch), not refresh
          if (this.firstSearch && this.nextJobNid && this.status === 'Active' && this.firm.fid === this.nextJobFid) {
            this.search[this.firm.fid] = this.nextJobNid.toString();
            let index = this.notes.findIndex(x => x.nid === this.nextJobNid);
            if (index !== -1) {// if nextJobNid is in this batch and is not expanded, expand it
              if (this.expanded.indexOf(this.notes[index]) === -1)  this.handleExpansion(this.notes[index]);
            } else {
              // if nextJobNid isn't in this batch and this isn't the last batch
              // and all the subBatches have been retrieved, continue search in next batch
              if (this.batch < this.batchCount && this.subBatch === this.subBatchCount) this.batch = this.batch + 1;
            }
          }
          //check to see if any of the previously expanded notes are in our new set. If so, expand them.
          this.expanded = this.notes.filter(note => {
            for (let [i, expandedNote] of this.expanded.entries()) {
              if (expandedNote.nid === note.nid) {
                this.expanded.splice(i, 1);
                return true;
              }
            }
            return false;
          });
          let expandedCheck = this._.unionWith(this.noteData.expanded, this.expanded, _.isEqual);
          //check all the expanded notes to get the notefiles
          for (let expandedNote of expandedCheck) this.getNoteFiles(expandedNote);
          this.expanded = expandedCheck;

          // if (this.subBatch !== this.subBatchCount) {// sometimes subBatch > subBatchCount
          if (this.subBatch < this.subBatchCount) {
            this.subBatch++;
            this.callNotesApi();
          }
        }).catch(e => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
      },

      callNotesApiForWebins: async function (item) {//get status notes, web notes for main notes table

        let searchParams = {
          page: this.batch,
          perPage: this.batchSize,
          searchcolumn: 'cstamp',
          sortorder: 'DESC',
          whereObjects: {
            fid: this.firm.fid,
            type: ['web in', 'web']
          }
        };
        this.savedWebNote = item;// save item in case refresh button clicked
        this.status = 'Web';
        this.loading = true;
        try {
          const webins = await notesAPIService.searchNotes(searchParams, this.$router);
          if (webins) {
            this.loading = false;
            this.notes = [];
            // loop through notes selecting ones that are within 6 months of item.cstamp
            for (let note of webins.result) {
              if ((moment(item.cstamp).subtract({ days: 1, months: 6 }) < moment(note.cstamp))
                && (moment(note.cstamp) < moment(item.cstamp).add( { days: 1, months: 6 })))
              {
                this.editRightsSet(note);
                this.notes.push(note);
              }
            }
            // now loop through all the selected notes and get all the note files
            for (let note of this.notes) this.getNoteFiles(note);
          }
        } catch (e) {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        }
      },
    },
  }
</script>

<style>
  td > span > span {
    white-space: pre-wrap;
  }

  pre {
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
  }

  .scroll {
    overflow-y: scroll
  }

  .v-input--switch .v-input--selection-controls__input {
    margin-right: 3px;
  }

  .leftLabel .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    margin-left: 3px;
    margin-top: -1px;
  }

  .lowerLabel .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    margin-top: 7px;
  }

  .v-application--is-ltr .v-data-footer__select {
    height: 45px;
  }

  .lower.v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections {
    margin-bottom: -5px;
  }

  .borderless input {
    border: 0;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6) !important;
  }

</style>



