<template>
  <div>
    <!--  FIELDS  -->
    <v-card class="px-1 pt-1 pb-5" :key="refreshAll">
      <v-form ref="fields" v-model="valid">
        <v-card-title class="ml-1 ml-sm-2 mt-12 mt-sm-0 h5 font-weight-regular" primary-title>
          {{!$_can('contact-edit') ? 'View' : id === 'Add' ? 'Add' : 'Edit'}} Contact
          <information-dialog-icon confirmClass="mr-3 title" confirmColor="secondary darken-1"
                                   tclass="headline grey lighten-2 mb-8" title="Contact Information" v-can="'contact-edit'">
            <p>This form allows you to {{id === 'Add' ? 'ADD' : 'EDIT'}} a contact and people below.</p>
            <p>When "Name 1", "Type", and "Sort" fields are filled out and valid
              (if there are any people below, their fields must also be valid), the
              "{{id === 'Add' ? 'ADD' : 'SAVE'}}<span v-if="$vuetify.breakpoint.name !== 'xs'"> ALL</span>"
              button will be enabled {{id === 'Add' ? '' : 'if there are changes to save'}}.</p>
            <p>Clicking the "{{id === 'Add' ? 'ADD' : 'SAVE'}}<span v-if="$vuetify.breakpoint.name !== 'xs'"> ALL</span>"
              button, or pressing enter in a field (except Note field), will {{id === 'Add' ? 'add' : 'save'}} the
              contact and also any people that have been {{id === 'Add' ? '' : 'edited/'}}added below
              (unless any fields are invalid).</p>
            <p v-if="dialog">Clicking "X" in the upper-right corner will cancel
              {{id === 'Add' ? ' ' : 'editing/'}}adding the contact/people and close this form.</p>
          </information-dialog-icon>
          <v-spacer/>
          <v-btn class="mr-0" @click="save()" color="secondary"
                 :disabled="!valid || !validPeople || !anyFieldsEdited()" :loading="saving" v-can="'contact-edit'">
            {{id === 'Add' ? 'Add' : 'Save'}} <span v-if="$vuetify.breakpoint.name !== 'xs'">&nbspAll</span>
          </v-btn>
          <v-btn class="ml-3 mr-n2" @click="$emit('close-dialog'), clearContactAndPeople()" icon text v-if="dialog">
            <v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card class="px-5 pt-1 pb-12 elevation-0 scroll">
          <v-row class="d-flex flex-row flex-nowrap">
              <span class="pb-1 mx-2 relative-container" :key="col" v-for="(col, index) in columns">
                <span
                    class="mx-5 justify-center"
                    :key="field.name"
                    v-for="(field, innerIndex) in fields[index]"
                >
                  <!-- column 0:'name1','name2','address1','address2','email' --  column 1:'type' -->
                  <smart-input
                    allow-str
                    attach
                    :autofocus="field.val === 'name1' && id === 'Add'"
                    :chips="field.val === 'type'"
                    class="my-n2 changedfield small"
                    :clean-value="contactClean[field.val]"
                    clearable
                    :deletable-chips="field.val === 'type'"
                    dense
                    :dirty-check="!loading && id !== 'Add'"
                    @enter="(valid === true && anyFieldsEdited() && validPeople === true) ? save() : ''"
                    :items="['type'].includes(field.val) ? types : []"
                    :label="field.name"
                    :menu-props="{ contentClass: 'compact narrow' }"
                    :multiple="field.val === 'type'"
                    :readonly="!$_can('contact-edit')"
                    :rules="rules[field.val]"
                    :small-chips="field.val === 'type'"
                    :style="{width: field.width ? field.width : fieldWidthLong, marginTop: field.val === 'type' ? '-8px !important' : ''}"
                    :tabindex="(innerIndex + 1).toString()"
                    :type="field.select ? 'VAutocomplete' : 'VTextField'"
                    v-if="['name1','name2','address1','address2','email','type'].includes(field.val)"
                    v-model="contact[field.val]"
                  />
                  <!-- column 0 - city, state & zip - very short fields all in a row -->
                  <v-row class="d-flex flex-row" v-else-if="['cityStateZip'].includes(field.val)">
                    <span class="ml-4" :key="field.name" v-for="field in fieldsAddressParts">
                      <smart-input
                        allow-str
                        attach
                        class="mx-n1 mt-2 mb-1 changedfield small"
                        :clean-value="contactClean[field.val]"
                        clearable
                        dense
                        :dirty-check="!loading && id !== 'Add'"
                        @enter="(valid === true && anyFieldsEdited() && validPeople === true) ? save() : ''"
                        :items="field.select ?  $_lists['states'] : []"
                        :label="field.name"
                        :menu-props="{ contentClass: 'compact xshort'}"
                        :readonly="!$_can('contact-edit')"
                        :tabindex="(innerIndex + 1).toString()"
                        :type="field.select ? 'VAutocomplete' : 'VTextField'"
                        :style="{width: field.width}"
                        v-model="contact[field.val]"
                      />
                    </span>
                  </v-row>

                  <!-- column 1 -->
                  <v-row class="d-flex flex-row flex-nowrap" v-if="['fieldsShort'].includes(field.val)">
                    <v-col style="margin-top: -16px">
                      <span :key="field.name" v-for="(field, innerIndex) in fieldsShort">
                        <!-- v-mask fields phone, fax not compatible with smart-input -->
                        <v-text-field
                          :append-outer-icon="appendOuterIcon(field)"
                          class="changedfield small"
                          :clearable="$_can('contact-edit')"
                          @click:append-outer="contact[field.val] = contactClean[field.val], keyRefreshMask++"
                          dense
                          :key="keyRefreshMask"
                          @keyup.enter="(valid === true && validPeople === true && anyFieldsEdited())? save() : ''"
                          hint="10 digit (XXX) XXX-XXXX"
                          :label="field.name"
                          :readonly="!$_can('contact-edit')"
                          :style="{margin: '3px 12px 12px 0px', width: fieldWidthShort}"
                          :tabindex="(innerIndex + 2).toString()"
                          v-if="['phone', 'fax'].includes(field.val)"
                          v-mask="'(###) ###-####'"
                          v-model="contact[field.val]"
                        >
                        </v-text-field>
                        <!-- remaining fields column 1 sort, FEIN, website -->
                        <smart-input
                          allow-str
                          class="changedfield small"
                          :clean-value="contactClean[field.val]"
                          clearable
                          dense
                          :dirty-check="!loading && id !== 'Add'"
                          @enter="(valid === true && anyFieldsEdited() && validPeople === true) ? save() : ''"
                          :label="field.name"
                          :readonly="!$_can('contact-edit')"
                          :rules="rules[field.val]"
                          :tabindex="(innerIndex + 2).toString()"
                          :style="{margin: '12px 12px 12px 0px', width: fieldWidthShort}"
                          v-else
                          v-model="contact[field.val]"
                        />
                      </span>
                    </v-col>
                    <v-col>
                      <smart-input
                        allow-str
                        class="ml-n4 changedfield small"
                        :clean-value="contactClean.note"
                        clearable
                        dense
                        :dirty-check="!loading && id !== 'Add'"
                        :label="'Note'"
                        :readonly="!$_can('contact-edit')"
                        :rows="'9'"
                        :tabindex="(innerIndex + 1).toString()"
                        :type="'VTextarea'"
                        :style="{width: fieldWidthNote, marginTop: '-10px'}"
                        v-model="contact.note"
                      />
                    </v-col>
                  </v-row>
                </span>
              </span>
          </v-row>
        </v-card>
      </v-form>

      <!-- add people -->
      <v-form ref="peopleFields" v-model="validPeople">
        <v-card-title class="ml-1 ml-sm-2 mt-n6 h5 font-weight-regular" primary-title>
          {{!$_can('contact-edit') ? 'View' : id === 'Add' ? 'Add' : 'Edit'}} People
          <information-dialog-icon confirmClass="mr-3 title" confirmColor="secondary darken-1"
                                   tclass="headline grey lighten-2 mb-8" title="People Information"
                                   v-can="'contact-edit'">
            <p class="mt-n6 mt-sm-n2">Below you can {{id === 'Add' ? ' ' : 'edit any people or'}} add a new person or people.</p>
            <p>Click
              <v-btn class="mt-n1" color="secondary" dark fab readonly style="transform: scale(.9)" x-small>
                <v-icon>mdi-plus</v-icon>
              </v-btn> to add a row of blank fields for adding a new person.
            </p>
            <p v-if="id !== 'Add'">Click
              <v-btn class="mt-n1" color="error lighten-1" dark fab readonly style="transform: scale(.9)" x-small>
                <v-icon>mdi-link-off</v-icon>
              </v-btn> to unlink person from the contact and remove row of fields.
            </p>
            <p>Click
              <v-btn class="mt-n1" color="warning lighten-2" dark fab readonly style="transform: scale(.9)" x-small>
                <v-icon>mdi-minus</v-icon>
              </v-btn> to remove a row of fields for adding a new person.
            </p>
            <p>Clicking "{{id === 'Add' ? 'ADD' : 'SAVE'}}<span v-if="$vuetify.breakpoint.name !== 'xs'"> ALL</span>"
              at the top, or pressing enter in a field (except Note field), will {{id === 'Add' ? 'add' : 'save'}}
              the contact, the people, and also any people that have been added (unless any fields are invalid).</p>
            <p>Each person is required to have a surname.</p>
            <p class="mb-n4" v-if="dialog">Clicking "X" in the upper-right corner will cancel all
              {{id === 'Add' ? ' ' : 'editing/'}}adding and close this form.</p>
          </information-dialog-icon>
          <v-spacer/>
          <button-tip @click="addPersonRow" color="secondary" elevation="5" fab icolor="white" iname="mdi-plus"
                      tip="Add row for person" v-can="'contact-edit'" xsmall/>
        </v-card-title>
        <v-card class="pt-2 mt-n1 elevation-0 scroll" style="padding-bottom: 160px">
          <v-row class="mx-1 pb-1 d-flex flex-row flex-nowrap"
                 :key="index" v-for="(row, index) in people.length">
            <button-tip btnclass="mt-5" @click="unlinkPerson(index)"
                        :color="index >= numLinkedPeople ? 'warning lighten-2' :' error lighten-1'" fab icolor="white"
                        :iname="index >= numLinkedPeople ? 'mdi-minus' : 'mdi-link-off'"
                        :tip="index >= numLinkedPeople ? 'Delete new person row' : 'Unlink person from contact & delete row'"
                        v-can="'contact-edit'" xsmall/>
            <v-row class="mx-1 smallfield" :key="innerIndex + refreshPeopleFields"
                   v-for="(field, innerIndex) in peopleFields">
              <!-- v-mask fields not compatible with smart-input -->
              <v-text-field
                :append-outer-icon="appendOuterIconPeople(field, index)"
                class="changedfield smallmessage"
                :clearable="$_can('contact-edit')"
                @click:append-outer="people[index] ? (people[index][field.val] = peopleClean[index][field.val], keyRefreshMask++) : ''"
                dense
                :key="keyRefreshMask"
                @keyup.enter="(valid === true && anyFieldsEdited() && validPeople === true) ? save() : ''"
                hint="(XXX) XXX-XXXX"
                :label="field.name"
                :readonly="!$_can('contact-edit')"
                :style="{width: fieldWidthPeoplePhones}"
                :tabindex="(index*9 + innerIndex + 7).toString()"
                v-if="['direct','mobile','home','people_fax'].includes(field.val)"
                v-mask="'(###) ###-####'"
                v-model="people[index][field.val]"
              >
              </v-text-field>
              <smart-input
                allow-str
                class="changedfield"
                :clean-value="peopleClean[index] ? peopleClean[index][field.val] : null"
                clearable
                dense
                :dirty-check="index < numLinkedPeople && !loading && id !== 'Add'"
                @enter="valid === true && validPeople === true ? save() : ''"
                :label="field.name"
                :readonly="!$_can('contact-edit')"
                :rules="field.val === 'surname' ? rules.name1 : field.val === 'people_email' ? rules.peopleEmail
                    : rules[field.val]"
                :style="{marginTop: field.val === 'given' ? '24px' : '',  width: field.width ? field.width : '110px'}"
                :tabindex="(index*9 + innerIndex + 7).toString()"
                v-else
                v-model="people[index][field.val]"
              />
            </v-row>
          </v-row>
        </v-card>
      </v-form>
    </v-card>

    <ActionDialog :value="confirmUnlinkPerson" max-width="500" divider confirm-text="Unlink Person"
                  title="Unlink" close-text="Cancel" confirm-color="error" persistent
                  @confirm="unlinkPersonConfirmed" @close="confirmUnlinkPerson = false">
      <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
        <span v-if="people && people[personToUnlinkIndex]">
          Unlink<br><br>
          <b>{{people[personToUnlinkIndex].given}} {{people[personToUnlinkIndex].surname}}</b><br><br>
          from<br><br>
          <b>{{contact.name1}}?</b><br><br>
        </span>
      </v-alert>
    </ActionDialog>
    <ErrorDialog ref="errorDialog"/>
    <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='EditContact'"></snack>
  </div>
</template>

<script>
  import ActionDialog from '../../ActionDialog';
  import { ContactAPIService } from "@/servicehandlers/ContactAPIService";
  import { PeopleAPIService } from "@/servicehandlers/PeopleAPIService";

  const contactAPIService = new ContactAPIService();
  const peopleAPIService = new PeopleAPIService();

  export default {
    name: 'EditContact',
    components: { ActionDialog },
    props: ['contactDialog', 'dialog', 'id', 'refresh'],
    data: () => ({
      columns: 2,
      confirmUnlinkPerson: false,
      contact: {},
      contactClean: {},
      fields: [
        [// column 0
          {name: 'Name 1',   val: 'name1'},
          {name: 'Name 2',   val: 'name2'},
          {name: 'Address 1',val: 'address1'},
          {name: 'Address 2',val: 'address2'},
          {val: 'cityStateZip'},// divided into addressParts: city, state, zip
          {name: 'Email',    val: 'email'},
        ],
        [// column 1
          {name: 'Type',     val: 'type', width: '600px', select: true},
          {val: 'fieldsShort'},// inner columns: col 0 - phone, fax, FEIN, website and col 1 - note
        ],
      ],
      fieldsAddressParts: [
        {name: 'City', val: 'city', width: '200px'},
        {name: 'State',val: 'state',width: '137px', select: true, list: 'states'},
        {name: 'Zip',  val: 'zip',  width: '147px'},
      ],
      fieldsShort: [
        {name: 'Sort',   val: 'sort'},
        {name: 'Phone',  val: 'phone'},
        {name: 'Fax',    val: 'fax'},
        {name: 'FEIN',   val: 'fein'},
        {name: 'Website',val: 'website'},
      ],
      fieldWidthLong: '500px',
      fieldWidthNote: '380px',
      fieldWidthPeoplePhones:'128px',
      fieldWidthShort:'200px',
      keyRefreshMask: 0,// increment this num to trigger keyed refresh of masked fields after reverting value - phone, etc
      loading: false,
      numLinkedPeople: 0,
      people: [],
      peopleClean: [],
      personNew: [],
      personToUnlinkIndex: '',
      peopleFields: [
        {name: 'Given Names',  val: 'given'},
        {name: 'Surname',      val: 'surname'},
        {name: 'Direct',       val: 'direct'},
        {name: 'Ext.',         val: 'ext',  width: '108px'},
        {name: 'Mobile',       val: 'mobile'},
        {name: 'Home',         val: 'home'},
        {name: 'Fax',          val: 'people_fax'},
        {name: 'Salutation',   val: 'salutation',  width: '111px'},
        {name: 'Email',        val: 'people_email',width: '137px'},
      ],
      refreshAll: false,
      refreshPeopleFields: false,
      rules: {
        email: [(x) => !x || /.+@.+\..+/.test(x) || 'Email must be valid'],
        peopleEmail: [(x) => !x || /.+@.+\..+/.test(x) || 'Email must be valid'],
        ext: [
          (x) => (!x || x.length <= 10) || 'Limit 10 digits',
          (x) => !x || /^[0-9]*$/.test(x) || 'Numbers only',
        ],
        name1: [x => !!x || 'Required'],
        sort: [
          (x) => !!x || 'Required',
          (x) => (x && x.length <= 10) || 'Sort must be no more than 10 characters',
        ],
        type: [x => x.length > 0 || 'Required'],
        // type: [x => x && x.length > 0 || 'Required'],
      },
      saving: false,
      valid: false,
      validPeople: true,
    }),

    computed: {
      types() {
        if (this.$_lists && this.$_lists.ctype && this.$_lists.ptype) return [...this.$_lists.ctype,...this.$_lists.ptype];
      },
    },

    mounted() {
      this.id === 'Add' ? this.$nextTick(() => {if (this.$refs.fields) this.$refs.fields.validate()}) : this.getContactById();
    },

    watch: {
      contactDialog() {
        if (this.contactDialog) this.getContactById();// if the editContact dialog is opened then getContactById()
      },
    },

    methods: {
      addPersonRow() {//
        // newPerson fields must be set to null for dirtycheck to work properly
        let newPerson = { given: null, surname: null, ext: null, salutation: null, people_email: null };
        this.people.push(newPerson);
        this.peopleClean.push(newPerson);
        this.people = JSON.parse(JSON.stringify(this.people));
        this.peopleClean = JSON.parse(JSON.stringify(this.peopleClean));
        this.$nextTick(() => {if (this.$refs.peopleFields) this.$refs.peopleFields.validate()});
      },

      anyFieldsEditedContact() {// have any of the contact fields been edited?
        if (!this.contact) return;
        let fieldEdited = false;
        for (let f in this.contact) {
          // check each field to see if value != clean value, except phone & fax, first strip mask from value, then compare
          if (['phone','fax'].includes(f)) {
            let fieldWithAnyMaskRemoved = this.contact[f] ? this.contact[f].replace(/\D/g, '') : this.contact[f];
            if (!this.$_eq(fieldWithAnyMaskRemoved, this.contactClean[f])) fieldEdited = true;
          } else {
            if (!this.$_eq(this.contact[f], this.contactClean[f])) fieldEdited = true;
          }
        }
        return fieldEdited;
      },

      anyFieldsEditedPeople() {// have any of the people fields been edited?
        if (!this.people.length) return;
        let fieldEdited = false;
        for (let [index, p] of this.people.entries()) {
          for (let f in p) {
            // check each field to see if value != clean value, except phone#s, first strip mask from value, then compare
            if (['direct','mobile','home','people_fax'].includes(f)) {
              let fieldWithAnyMaskRemoved = p[f] ? p[f].replace(/\D/g,'') : p[f];
              if (!this.$_eq(fieldWithAnyMaskRemoved, this.peopleClean[index][f])) fieldEdited = true;
            } else {
              if (!this.$_eq(p[f], this.peopleClean[index][f])) fieldEdited = true;
            }
          }
        }
        return fieldEdited;
      },

      anyFieldsEdited() {// have any of the fields been edited?
        return this.anyFieldsEditedContact() || this.anyFieldsEditedPeople();
      },

      appendOuterIcon(field) {// smartInput.vue and v-mask don't work properly together, so handle internally
        if (this.loading || this.id === 'Add') return;// no revert if loading, or no id
        // if the field is not null, then there is a mask, so remove it before comparing
        let fieldWithAnyMaskRemoved = this.contact[field.val] ? this.contact[field.val].replace(/\D/g, '') : this.contact[field.val];
        let dirty = !this.$_eq(fieldWithAnyMaskRemoved, this.contactClean[field.val]);
        // if the contact field and the contactClean field are not equal, field is dirty so return revert for outer icon
        if (dirty) return 'mdi-arrow-u-left-top';
      },

      appendOuterIconPeople(field, index) {// smartInput.vue & v-mask don't work properly together, so handle internally
        // no revert outer icon if loading, no id, or if a new person/row
        if (this.loading || this.id === 'Add' || index >= this.numLinkedPeople) return;
        // if the field is not null, then there is a mask, so remove it before comparing
        let fieldWithAnyMaskRemoved = this.people[index][field.val] ? this.people[index][field.val].replace(/\D/g, '') : this.people[index][field.val];
        let dirty = !this.$_eq(fieldWithAnyMaskRemoved, this.peopleClean[index][field.val]);
        // if the people field and the peopleClean field are not equal, field is dirty so return revert for outer icon
        if (dirty) return 'mdi-arrow-u-left-top';
      },

      clearContactAndPeople() {// clear fields and reset validation
        for (const key in this.contact) this.contact[key] = '';
        this.people = [];
        if (this.$refs.fields) this.$refs.fields.reset();
      },

      getContactById() {
        this.loading = true;
        contactAPIService.getContactById(this.id, this.$router).then(res => {
          let types = res.type.split(/[-~]/);// ~Underwriter~E&O -> Underwriter E&O
          for (let type of types) if (types[0] === '') types.shift();// remove leading spaces
          res.type = types;
          this.contact = JSON.parse(JSON.stringify(res));
          this.contactClean = JSON.parse(JSON.stringify(res));
          this.keyRefreshMask++;// trigger v-mask to re-render in phone fields
          res.people.length ? this.getPeopleByCid() : this.loading = false;
        })
            .catch(e => {
              this.loading = false;
              this.$refs.errorDialog.showError({
                forDevelopers: e
              })
            });
      },

      getPeopleByCid() {// for when EditContact.vue is used for editing
        if (this.id === 'Add') return;// this is a new contact so there are no linked people yet
        peopleAPIService.getPeopleByCid(this.id, this.$router).then(people => {
          for (let person of people) {// change all undefined values into null so dirtycheck will work
            for (let field of this.peopleFields) if (person[field.val] === undefined) person[field.val] = null;
          }
          this.numLinkedPeople = people.length;
          this.people = JSON.parse(JSON.stringify(people));
          this.peopleClean = JSON.parse(JSON.stringify(people));
          this.loading = false;
          this.keyRefreshMask++;// trigger v-mask to re-render in phone fields
        })
            .catch(e => {
              this.$refs.errorDialog.showError({
                forDevelopers: e
              })
            });
      },

      removePhoneMasks(data) {// remove phone masks for saving to database
        for (let d of data) {
          for (let f of ['phone', 'fax', 'people_fax', 'direct', 'mobile', 'home']) {
            if (d[f]) d[f] = d[f].replace(/[^\d]/g, '');
          }
        }
        return data;
      },

      save() {
        let msg = '';
        this.saving = true;
        msg = `Contact "${this.contact.name1} - ${this.contact.sort}" successfully edited`;
        let contactToSave = JSON.parse(JSON.stringify(this.contact));// make separate copy, so mask removal doesn't show
        contactToSave.type = "~" + contactToSave.type.join("~");// join elements of type array and prepend ~
        [contactToSave] = this.removePhoneMasks([contactToSave]);// removePhoneMasks expects array
        if (this.id !== 'Add') {// edit or copy existing contact
          contactAPIService.updateContact(this.id, contactToSave, this.$router).then(res => {
            this.savedNowProcess(res, msg);
          })
              .catch(e => {
                this.saving = false;
                this.$refs.errorDialog.showError({forDevelopers: e})
              });
        } else {// add new contact
          contactAPIService.createContact(contactToSave, this.$router).then(res => {
            msg = `New contact "${res.name1} - ${res.sort}" successfully added`;
            this.savedNowProcess(res, msg);
          })
              .catch(e => {
                this.saving = false;
                this.$refs.errorDialog.showError({forDevelopers: e})
              });
        }
      },

      savedNowProcess(res, msg) {// if Add Person field/s were filled, use returned contact id to createPeople
        let cid = res.cid ? res.cid : this.contact.cid;
        if (this.people.length) {
          let peopleToSave = JSON.parse(JSON.stringify(this.people));// make separate copy, so mask removal doesn't show
          peopleToSave = this.removePhoneMasks(peopleToSave);
          // need to add cid to each person in peopleToSave to set which contact these people are associated with
          for (let person of peopleToSave) {
            if (person.people_email) person.email = person.people_email;// api expects .email, not people_email
            if (person.people_fax) person.fax = person.people_fax;// api expects .email, not people_email
            delete person.people_cid;// to avoid api error
            delete person.people_fax;// to avoid api error
            delete person.people_email;// to avoid api error
            delete person.sort;// to avoid api error
            person.cid = cid;
          }
          if (peopleToSave.length) peopleAPIService.createOrUpdate(peopleToSave, this.$router).then(response => {})
              .catch(e => {
                this.$refs.errorDialog.showError({forDevelopers: e})
              });
        }
        setTimeout(()=>{
          this.$store.dispatch('SET_SNACK', { cmpnt: 'EditContact', open: true, msg: msg});
          this.$emit("update:refresh", true); //refreshes the contacts table after submit
          this.$emit("view-contacts");
          this.$emit("contact-added", res);// EditProgram uses this event to trigger updating contact dropdown list
          this.id === 'Add' ? this.clearContactAndPeople() : this.getContactById();
          this.saving = false;
          this.refreshAll = !this.refreshAll;
        }, 500)// .5 second delay

      },

      splicePerson(index) {// delete row from people and peopleClean arrays
        this.people.splice(index, 1);
        this.peopleClean.splice(index, 1);
      },

      unlinkPerson(index) {// if person/row is already saved/linked to a contact, then unlink, else just delete/splice row
        if (this.people[index].people_cid) {
          this.personToUnlinkIndex = index;
          this.confirmUnlinkPerson = true;
        } else this.splicePerson(index);
      },

      unlinkPersonConfirmed() {
        let index = this.personToUnlinkIndex;
        let personToUnlink = JSON.parse(JSON.stringify(this.people[index]));// make separate copy, so deletes don't show
        personToUnlink.cid = null;
        delete personToUnlink.people_cid;// to avoid api error
        delete personToUnlink.people_fax;// to avoid api error
        delete personToUnlink.people_email;// to avoid api error
        delete personToUnlink.sort;// to avoid api error
        this.confirmUnlinkPerson = false;
        peopleAPIService.updatePerson(personToUnlink.plid, personToUnlink, this.$router).then(res => {
          let msg = `Person "${this.people[index].surname}" successfully unlinked from contact`;
          this.$store.dispatch('SET_SNACK', { cmpnt: 'EditContact', open: true, msg});
          this.numLinkedPeople--;
          this.splicePerson(index);
        })
        .catch(e => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
      },
    }
  };
</script>

<style scoped>
</style>
