<template>
  <div class="px-1 pb-9" :class="dialog ? 'pt-12 pt-sm-2 mt-1 pb-1 mb-n12 mb-sm-0' : 'pt-1'"><!--needs diff spacing-->

    <!--      Buttons    -->
    <span ref="aboveNoteBodyHeight">
      <v-row class="px-4 pt-0 mt-0 mb-n2" style="cursor: pointer; z-index: 7">
        <button-tip btnclass="mr-n2" btnstyle="margin: 15px 0 0 0; z-index: 7"
                    @click="expandComments = !expandComments, click('comments')" icolor="secondary darken-2"
                    icon iname="mdi-comment-outline" tip="Show/Hide/Add comments"/>
        <v-card class="pa-1 pt-0 scroll elevation-0" @click="expandComments = true, click('comments')"
                color="grey lighten-3" style="line-height: .5em; margin: 10px 0 12px 12px; white-space: pre-wrap"
                :style="dialog && $vuetify.breakpoint.name === 'xs' ? 'width: calc(100vw - 285px)'
                : 'width: calc(100vw - 330px)'" height="45" v-if="!expandComments">
          <span class="py-0 my-0 caption" style="line-height: .8em">{{note.tasks}}</span>
        </v-card>
        <v-textarea auto-grow class="px-3 mt-3 mb-n2 caption denseTextarea" @click="click('comments')" dense
                    placeholder="Comment - office use only..." :readonly="notUser" rows="1"
                    :style="dialog && $vuetify.breakpoint.name === 'xs' ? 'width: calc(100vw - 270px)'
                    : 'width: calc(100vw - 315px)'" v-if="expandComments" v-model="newTask"/>
        <v-spacer/>
        <div class="pt-1" v-if="!editEmail">
          <v-btn class="ml-n3 mr-0" @click="click('prep'), getRecipients()" color="secondary darken-2"
                 text v-if="!editEmail && ['memo', 'email out'].includes(note.type) && !notUser" x-large>
            Prep
          </v-btn>
          <v-btn @click="updateReThenSaveWholeNote(note.nid, note)" color="secondary darken-2" text x-large>
            Save
          </v-btn>
        </div>
        <div class="pt-1" v-if="editEmail">
          <!--    transparent wrapper button to inform user with missing field dialog when SEND button is disabled  -->
          <v-btn class="pa-0 ml-n3 mr-0 my-0" @click="missingFieldDialog = missingField" :disabled="warningDisabled" text
                 v-if="rightsArray.includes('email-send') && ['memo', 'email out'].includes(note.type)" x-large>
            <v-btn @click="checkBeforeSend()" color="secondary darken-2"
                   :disabled="missingField || note.status === 'done'" :loading="sending" text x-large>
              Send
            </v-btn>
          </v-btn>
          <v-btn @click="saveEmail(to, from, cc, bcc)" color="secondary darken-2" :disabled="sending" text x-large>
            Save
          </v-btn>
        </div>
        <v-btn class="px-0 pt-2" @click="$emit('close-dialog')" color="secondary darken-2"
               style="min-width:40px; z-index: 7" text v-show="!!dialog" x-large>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <!--      Note fields - comments   -->
      <v-card class="pa-1 mx-4 mb-2 scroll elevation-0" color="grey lighten-3" max-height="calc(20vh + 20px)"
              min-height="45" style="white-space: pre-wrap; line-height: .5em" v-if="expandComments">
        <span class="py-0 my-0 caption" style="line-height: .8em">{{note.tasks}}</span>
      </v-card>

      <!--      Note fields - other   -->
      <v-row class="px-4 pt-1 mt-1 mb-n6" @click="click('note')">
        <v-row class="px-0 mx-0 my-n3">
          <v-col cols="6" style="margin-top: -2px">
            <v-row v-if="['status', 'memo', 'email out'].includes(note.type) && !notUser"
                   class="px-3 pb-4 mt-0" color="secondary--text text--darken-2 font-weight-medium">
              <div class="pr-6 line">
                <datetime type="datetime" v-model="note.cstamp"
                          :week-start="7" format="dd MMM yyyy hh:mm a" class="theme-dominionBlue point"
                          use12-hour auto
                ></datetime>
              </div>
            </v-row>
            <v-row v-else class="px-3 pt-3 pb-3">
              <div class="line grey--text" style="margin-bottom: -2px; min-width: 165px">
                {{ moment(note.cstamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A')}}
              </div>
            </v-row>
          </v-col>
          <v-col cols="6">
            <smart-input attach class="changedfield" :clean-value="noteClean.type" clearable dense :dirty-check="!noteLoading"
                         :items="typeItems" item-disabled="disabled" item-value="text" label="Type" revert-tip
                         :readonly="notUser || (['email in', 'fax in', 'web in'].includes(note.type) && !groups.includes('admin'))"
                         :type="'VAutocomplete'" v-model="note.type"
            ></smart-input>
          </v-col>
        </v-row>
        <v-row class="px-0 mx-0 my-n3">
          <v-col cols="6">
            <smart-input attach class="changedfield" :clean-value="noteClean.status" clearable dense :dirty-check="!noteLoading"
                         :items="statusItems" item-value="text" label="Status" :readonly="notUser" revert-tip
                         :type="'VAutocomplete'" v-model="note.status"
            ></smart-input>
          </v-col>
          <v-col cols="6">
            <smart-input attach class="changedfield" :clean-value="noteClean.staff" clearable dense :dirty-check="!noteLoading"
                         :items="forStaff" item-text='username' item-value='username' label="For" revert-tip
                         :type="'VAutocomplete'" v-model="note.staff"
            ></smart-input>
          </v-col>
        </v-row>
      </v-row>

      <!--  Email prep: policy, form/template -->
      <div class="pb-0 mt-2" @click="click('note')" v-if="editEmail && !['web in','web'].includes(note.type)">
        <v-row class="px-4 py-1 mb-n3">
          <v-col cols="12" md="6" class="py-0 mt-3 lowerLabel">
            <v-row class="justify-space-between">
              <smart-input attach
                           class="pl-3 pr-0 mt-2 mb-0 mr-0 changedfield"
                           :clean-value="policyClean"
                           clearable
                           dense
                           :dirty-check="!policyLoading"
                           :items="policies"
                           :item-value="'text'"
                           @input="pastePolicy(), note.pid = policy ? policy.pid : null"
                           label="Select policy or click + to paste in re line"
                           no-data-text="No booked policies"
                           :readonly="notUser"
                           return-object
                           revert-tip
                           :style="`width: ${policyFieldWidth}`"
                           :type="'VAutocomplete'"
                           v-model="policy"
              ></smart-input>
              <span class="pl-0 ml-0" style="margin-top: 9px">
                <button-tip @click="pastePolicy()" icon iname="mdi-plus" tip="Paste policy in re line"></button-tip>
                <button-tip @click="pastePolnum = true" icon iname="mdi-checkbox-blank-outline"
                            tip="Include policy# in re line paste" v-if="!pastePolnum"></button-tip>
                <button-tip @click="pastePolnum = false" icon iname="mdi-checkbox-marked"
                            tip="Include policy# in re line paste" v-if="pastePolnum"></button-tip>
                <span class="ml-n1 mr-4 mt-3">pol#</span>
              </span>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="py-0 mt-0">
            <smart-input attach
                         class="mt-3 letters"
                         clearable
                         @click-prepend="pasteFormConfirm()"
                         dense
                         :items="templates"
                         @input="note.frid = template ? template.frid : null"
                         label="Letters"
                         :prepend-icon="'mdi-content-paste'"
                         :prepend-tip="'Paste over existing format'"
                         :readonly="notUser"
                         return-object
                         style="padding-left: 6px"
                         :type="'VAutocomplete'"
                         v-model="template"
              ></smart-input>
          </v-col>
        </v-row>
      </div>

      <!--  Email prep: recipients -->
      <v-row class="px-4 py-0 mt-2 mb-n3" v-if="editEmail && expandRecipients">
        <v-row class="pa-0 mx-0 mt-n2 mb-n1 smoke">
          <v-col class="pb-0" cols="6">
            <v-autocomplete :search-input.sync="searchToPeople" dense v-model="to" :rules="[rules.required]"
                            :items="recipientsArray" label="To" return-object
                            :key="to.plid" :readonly="notUser" item-text="text"
                            item-value="plid" chips small-chips deletable-chips>
              <template v-slot:item="{ item }"><span v-html="item.dropdownText"></span></template>
            </v-autocomplete>
          </v-col>
          <v-col class="pb-0" cols="6">
            <v-autocomplete dense v-model="from" :rules="[rules.required]" :readonly="notUser"
                            :items="fromArray" label="From" chips small-chips deletable-chips>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="px-0 mx-0 mt-n2 mb-n1 smoke">
          <v-col class="pb-0" cols="6">
            <v-autocomplete :search-input.sync="searchCcPeople" dense v-model="cc" multiple chips small-chips
                            deletable-chips :items="recipientsArray" label="Cc"
                            return-object :readonly="notUser"
                            item-value="plid">
              <template v-slot:item="{ item }"><span v-html="item.dropdownText"></span></template>
            </v-autocomplete>
          </v-col>
          <v-col class="pb-0" cols="6">
            <v-autocomplete :search-input.sync="searchBccPeople" dense v-model="bcc" multiple :items="recipientsArray" chips small-chips deletable-chips
                            return-object item-value="plid" :readonly="notUser" label="Bcc" >
              <template v-slot:item="{ item }"><span v-html="item.dropdownText"></span></template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-row>
      <div class="pt-0 mt-1 mb-n3 recipients-style smoke" @click="click('recipients')"
           style="line-height: 1.2rem; z-index: 6" v-if="editEmail && !expandRecipients">
          Recipients: &nbsp {{to.given || ''}} {{to.surname || ''}}
        <span style="margin-left: -4px" v-for="(c,idx) of cc">
<!--          display comma if (c exists and TO exists) OR (c exists and idx > 0) -->
          {{((c.given || c.surname) && (to.given || to.surname)) || ((c.given || c.surname) && idx > 0)  ? ',' : ''}}
          {{c.given || ''}} {{c.surname || ''}}
        </span>
        <span class="mx-2 bcc" v-if="bcc.length">{{bcc.length}} Bcc</span>
      </div>

      <!--      Note field - RE line   -->
      <v-row class="px-4 pb-0 mb-n3">
        <v-col class="py-0 my-0">
          <information-dialog-icon icon-class="ml-3 mt-n3 mb-n8" title="HTML tags">
            If HTML tags are detected in the re line, it will be rendered here.
          </information-dialog-icon>
          <div class="caption" style="margin: -11px 0px -9px 48px" v-html="note.re" v-show="showHTML && !note.re.text"></div>
          <smart-input
                      attach
                      class="pb-1 pt-0 changedfield"
                      clearable
                      :clean-value="noteClean.re"
                      dense
                      :dirty-check="!noteLoading"
                      :items="reItems"
                      label="Re"
                      persistent-hint
                      :readonly="notUser"
                      ref="reField"
                      revert-tip
                      :rules="[rules.required]"
                      :type="'VCombobox'"
                      v-model="note.re"
          ></smart-input>
        </v-col>
      </v-row>

    </span><!--      END of span with ref=aboveNoteBodyHeight    -->

      <!--     Note body ckeditor/textarea/ifrome   -->
      <div :style="notebodyoffsetcssvar" v-if="note.type !== 'email in' || groups.includes('admin')">
        <div class="compactTextarea" v-if="!(note.type === 'web in' || note.type === 'web')">
          <span class="body-responsive-height" @click="click('editor')" v-if="note.html && !readonlyType && !notUser">
            <ckeditor :config="editorConfig" :editor="editor" type="classic" v-model="note.note"/>
          </span>
          <v-textarea class="mb-n5 mx-4" @click="click('editor')" label="Note Body"
                      no-resize :readonly="readonlyType || notUser" :rows="rows" v-else v-model="note.note"
          ></v-textarea>
          <v-row class="mt-0 mb-n1" justify="end" v-if="!readonlyType && !notUser">
            <v-btn v-if="note.html" text x-large color="secondary darken-2" @click="revertToPlainText">
              <span v-if="$vuetify.breakpoint.name !== 'xs'">Revert To Plain</span>&nbspText
            </v-btn>
            <v-btn v-else text x-large color="secondary darken-2" @click="convertToHtml">
              <span v-if="$vuetify.breakpoint.name !== 'xs'">Convert To</span>&nbspHTML
            </v-btn>
            <information-dialog-icon icon-class="mb-n5 mt-2 mr-6" title="Converting to an HTML Note">
              Converting a note to be an HTML note means that you will be provided an HTML editor to edit the note as
              opposed to a plain text box. This will add HTML tags to your note, and give you more styling options. You
              won't be able to see the HTML tags unless you edit the note in in.dominioninsurance.com. If you have already
              converted the note to be an HTML note, a "revert to plain text" button will appear, and the system will
              attempt to strip and replace any added HTML tags with plain text characters. Note that if you have a table
              or other additional non-text elements, there will be no way to remove these and maintain the contents
              of the note. Those HTML tags will remain in your note, even after reverting to plain text.
            </information-dialog-icon>
          </v-row>
        </div>
        <iframe class="mb-2 body-responsive-height" v-else :srcdoc="note.note" width="100%"></iframe>
      </div>

      <!--    Attachment Files    -->
      <v-row class="lowerLabel pt-0 pb-2" @click="click('note')" style="margin: -52px 0 -6px 16px"
             v-if="editEmail && ['memo', 'email out'].includes(note.type) && !notUser">
        <div class="mt-n3">
            <v-checkbox color="accent1"
                        hide-details
                        label="Send as attachment/s"
                        style="max-width: 200px"
                        v-model="sendAsAttachments"
            ></v-checkbox>
          <div v-if="sendAsAttachments" class="caption"
               :style="$vuetify.breakpoint.name === 'xs' ? 'max-width: calc(100vw - 190px)' : 'max-width: calc(100vw - 350px)'"
               style="color: red; padding-right: 20px; margin: -4px 2px; line-height: 1.2">
            Attachment order in email varies as determined by the
            email client (gmail, yahoo, etc.).  Filename extension required to send.
          </div>
        </div>
      </v-row>

      <span @click="click('note')">
        <EditFiles class="mt-1" :class="dialog ? 'mb-4' :''" v-bind="{ fid, groups, type, typeId, notUser, readonlyType }"
                   @filesArrayUpdated="filesArrayUpdated" ref="editFiles"/>
      </span>

      <!--   Alerts    -->
      <ActionDialog @close="confirmPasteDialog = false" close-text="Cancel"
                    @confirm="confirmPasteDialog = false, pasteForm()" confirm-color="error" confirm-text="Paste Over"
                    divider @input="$emit('input', $event)" max-width="700" title="Paste Over Warning"
                    :value="confirmPasteDialog">
        <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
          <strong>PASTE OVER</strong> existing re line/note, <strong>ERASING</strong> current content?
        </v-alert>
      </ActionDialog>

      <ActionDialog :value="confirmEmailDialog" @input="$emit('input', $event)" max-width="700"
                    title="Save and Send Email" close-text="Cancel" confirm-color="secondary" divider
                    @confirm="sending = true, confirmEmailDialog = false, saveEmail(to, from, cc, bcc)"
                    @close="sending = false, confirmEmailDialog = false" confirm-text="Send">
        Are you sure you want to save and send this email? (This could take 15 or more seconds,
        depending on various factors, including the number of attachments.)
        <div v-if="intervened"><br><i> {{note.staff}} has intervened.</i></div>
        <div class="pt-2 caption red--text" v-if="sendAsAttachments && filesWithoutExtensions.length"><br><br>
          <span>The following files have no file extension:</span>
          <v-row>
            <v-col cols="6">
              <div>Filename:</div>
              <div v-for="fle in filesWithoutExtensions"><i>{{ fle.filename }}</i></div>
            </v-col>
            <v-col cols="6">
              <div>Description:</div>
              <div v-for="fle in filesWithoutExtensions"><i>{{ fle.file_description }}</i></div>
            </v-col>
            <span class="mx-3">(Please upload files with file extensions. When "SEND" is clicked, only
              attachments with file extensions will be sent.)</span>
          </v-row>
        </div>
      </ActionDialog>

      <ActionDialog :value="missingFieldDialog" @input="$emit('input', $event)" max-width="700" persistent
                    title="Missing Field/s" confirm-text="Ok" confirm-color="red lighten-2"
                    :second-button="false" @confirm="missingFieldDialog = false">
        <v-divider color="red"></v-divider>
        Please be sure to fill the <strong>to, from, re, and note</strong> fields.
      </ActionDialog>

      <ActionDialog :value="invalidFrom" @input="$emit('input', $event)" max-width="700" persistent
                    title="Invalid From Field" confirm-text="Ok" confirm-color="red lighten-2"
                    :second-button="false" @confirm="invalidFrom = false">
        <v-divider color="red"></v-divider>
        <strong>{{ from }} </strong> is not currently available. Please select a different person to send this email
        from, and then try sending your email again.
      </ActionDialog>

      <ActionDialog :value="changingForStaffField" @input="$emit('input', $event)" max-width="700" persistent
                    title="Reassigning note" confirm-text="Ok" confirm-color="red lighten-2"
                    :second-button="false" @confirm="changingForStaffField = false">
        <v-divider color="red"></v-divider>
        Click 'SAVE' above to transfer edit privileges.
      </ActionDialog>

      <ActionDialog :value="changedForStaffField" @input="$emit('input', $event)" max-width="700"
                    title="Note is not in your name" confirm-text="Ok" confirm-color="red lighten-2"
                    :second-button="false" @confirm="changedForStaffField = false">
        <v-divider color="red"></v-divider>
        You have saved the change to the 'For' field so that this note is no longer in your name and you will
        not be able to edit it.  You can change the 'For' field back to your name and click 'SAVE'
        to re-enable editing.
      </ActionDialog>

      <v-snackbar app color="secondary" v-model="emailSending" style="top: 80px;" top>
        Sending...allow 15+ seconds...
        <v-btn style="margin-left: 60px" text @click="emailSending = false">Close</v-btn>
      </v-snackbar>

      <v-snackbar app color="secondary" v-model="emailSuccess" style="top: 80px;" top>
        Note was saved and email was sent.
        <v-btn style="margin-left: 60px" text @click="emailSuccess = false">Close</v-btn>
      </v-snackbar>

      <v-snackbar app color="error" v-model="emailError" style="top: 80px;" top timeout="-1">
        There was an error sending the email.
        <v-btn style="margin-left: 60px" text @click="emailError = false">Close</v-btn>
      </v-snackbar>

      <v-snackbar app color="secondary" v-model="draftSuccess" style="top: 10px" top>
        The note was saved.
        <v-btn style="margin-left: 100px" text @click="draftSuccess = false">Close</v-btn>
      </v-snackbar>

      <ErrorDialog ref="errorDialog"/>

  </div>
</template>

<script>
  import ActionDialog from '../../ActionDialog';
  import ClassicEditor from "ckeditor5-build-classic-plus";// npm install --save ckeditor5-build-classic-plus
  import EditFiles from "./EditFiles";
  import ErrorDialog from "../../ErrorDialog";
  import ListViewer from '@/components/ListViewer';
  import loginCheckMixin from '@/mixin';
  import moment from 'moment';
  import { ContactAPIService } from '@/servicehandlers/ContactAPIService';
  import { Datetime } from 'vue-datetime';
  import { EmailAPIService } from '@/servicehandlers/EmailAPIService';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { GroupsAPIService } from '@/servicehandlers/GroupsAPIService';
  import { ListsAPIService } from '../../../servicehandlers/ListsAPIService';
  import { NotesAPIService } from '@/servicehandlers/NotesAPIService';
  import { RecipientAPIService } from '@/servicehandlers/RecipientAPIService';
  import { UsersAPIService } from '@/servicehandlers/UsersAPIService';
  import { PeopleAPIService } from '@/servicehandlers/PeopleAPIService';
  import InformationDialogIcon from "../../InformationDialogIcon";
  import _ from "lodash";


  const firmsAPIService = new FirmsAPIService();
  const contactAPIService = new ContactAPIService();
  const emailer = new EmailAPIService();
  const groupsAPIService = new GroupsAPIService();
  const listsAPIService = new ListsAPIService();
  const notesAPIService = new NotesAPIService();
  const recipient = new RecipientAPIService();
  const usersAPIService = new UsersAPIService();
  const peopleAPIService = new PeopleAPIService();

  export default {
    name: 'EditNoteEmail',
    mixins: [loginCheckMixin],
    components: {
      InformationDialogIcon, EditFiles, ErrorDialog, Datetime, ActionDialog, ListViewer },
    props: {
      dialog: Boolean,
      id: [String, Number],
    },
    data: () => ({

      //--------- Validation Variables ---------//

      rules: { required: v => !!v || 'Field is required' },
      toError: false,
      valid: false,
      warningDisabled: true,

      //--------- Toggle Variables ---------//

      changedForStaffField: false,
      changingForStaffField: false,
      confirmEmailDialog: false,
      confirmedPaste: false,
      confirmPasteDialog: false,
      dateMenu: false,
      draftSuccess: false,
      editEmail: false,
      emailError: false,
      emailSending: false,
      emailSuccess: false,
      expandComments: false,
      expandRecipients: false,
      intervened: false,
      invalidFrom: false,
      missingFieldDialog: false,
      newDate: false,
      noteLoading: false,
      notUser: false,// flag to disable editing if note is not in user's name
      pastePolnum: false,
      policyLoading: false,
      retrieved: false,
      sendAsAttachments: true,
      sending: false,

      //--------- Data Variables ---------//

      allForms: [],
      appendClosing: '<br><br>Best regards,<br>',
      appendContact: '<br>Dominion Insurance Services<br>' +
        '341 South Main Street, Suite 100, Alpine, UT 84004<br>' +
        'Tel: (925) 313-9977 Fax: (925) 313-9978 <br>Email: ',
      appendDisclaimer: '<br><br>THIS MESSAGE IS INTENDED ONLY FOR THE USE OF THE INDIVIDUAL OR ENTITY TO WHICH IT IS ADDRESSED AND MAY CONTAIN INFORMATION WHICH IS PRIVILEGED, CONFIDENTIAL AND EXEMPT FROM DISCLOSURE UNDER APPLICABLE LAW. If the reader of this message is not the intended recipient or the agent responsible for delivering the message to the intended recipient, you are hereby notified that any dissemination, distribution or copying of this communication is strictly prohibited. If you have received this communication in error, please notify us immediately by telephone and then delete this email and destroy any hardcopy printouts of the same. If you do not wish to receive any future commercial (non-transactional) email messages, please reply with the following subject line: unsubscribe.',
      appendLicenseLink: '<br>Licenses: <a href="https://dominioninsurance.com/items/index/342/5">Click to View</a><br>',
      attachedFiles: [],
      attachedForms: [],
      bcc: [],
      blank: '  ',
      cc: [],
      debouncer: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'underline', 'bulletedList', 'numberedList', '|', 'blockQuote', 'insertTable', '|', 'undo', 'redo']
      },
      errors: [],
      fid: null,
      filesArray: [],
      firm: {},
      firmPeople: [],
      forStaff: [], // edit of note.staff dropdown select
      from: undefined,
      fromArray: [], // array of signers values
      groups: [],
      longName: '',
      newTask: '', // new task/comment to be concatenated to the old task
      note: {},
      notebodyoffsetcssvar: '',
      noteClean: {},
      noteData: {},
      people: [],
      previousStaff: '',
      policies: [],
      policy: {},
      policyClean: {},
      policyFieldWidth: '',
      re: [],
      recipients: [],
      reItems: [],
      rightsArray: [],
      rows: 25,
      search: '',
      searchToPeople: '',
      searchCcPeople: '',
      searchBccPeople: '',
      statusItems: [],
      template: {},
      templates: [],
      to: {
       plid: 0,
       plname: '',
      },
      type: 'note',// edit 'note' files in EditFiles.vue
      typeItems: [],
      userId: '', // for getting the staffArray from allowed_users
      username: '', // for sending the email
    }),

    watch: {
      'note.staff'() {
        // if staff is changed from username, alert user
        if (this.note.staff !== this.username) this.changingForStaffField = true;
      },

      searchToPeople(name){
        if(!name) return;
        this.debounceWrapper(name);
      },

      searchCcPeople(name){
        if(!name) return;
        this.debounceWrapper(name);
      },

      searchBccPeople(name){
        if(!name) return;
        this.debounceWrapper(name);
      },

      to() {
        if (this.to === undefined || this.to === null) this.to = {plid: 0, plname: ''};
      },
    },

    computed: {
      filesWithoutExtensions() {// from filesArray generate array of all files without filename extensions
        let noExtensions = [];
        for (let file of this.filesArray) if (!this.$_hasExtension(file.filename)) noExtensions.push(file);
        return noExtensions;
      },

      missingField() {
        const missing = (
             !this.to
          || !this.to.plid
          || !this.from
          || !this.note.re
          || !this.note.note
        );
        return missing;
      },

      readonlyType() {// if noteLoading or note type not editable, make fields readonly except status, staff, re, comments
        return this.noteLoading ? true : ['email in', 'fax in', 'web in'].includes(this.note.type)
            && !this.groups.includes('admin');
      },

      recipientsArray(){
        return this.people.concat(this.firmPeople)
      },

      showHTML() {
        return /<\/?[a-z][\s\S]*>/i.test(this.note.re);// check if re line has HTML tags
      },

      styledNote()  { return '<head><style> table {border-collapse: collapse; margin: 0 auto; } table, th, td ' +
        '{border: 1px solid black;} td {padding: 1em;}</style></head><body>' + this.note.note +
        '</body>'
      },

      typeId() {
        return this.note.nid; // EditFiles.vue attaches files to this note
      },
    },

    created() {
      window.addEventListener("click", this.adjustNoteBodyHeight);
      window.addEventListener("keydown", this.adjustNoteBodyHeight);
      window.addEventListener("resize", this.adjustNoteBodyHeight);
      this.adjustPolicyFieldWidth();
      this.rightsArray = this.loginCheckMixin(['email-send']);
      this.username = this.$store.getters.username;
      this.userId = this.$store.getters.userId;
      this.groups = this.$store.getters.groups;
      this.noteLoading = true;
      notesAPIService.getNote(this.id, this.$router).then((note) => {// use id to get note and firm
        if (note.staff !== this.username) {// comment username took note from staff, update note
          let date = moment(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A');
          let comment = `${date}  (${this.username})  - took from ${note.staff}`;
          note.tasks = note.tasks ? comment + '\n' + '\n' + note.tasks : comment;
          note.closer = note.staff;
          note.staff = this.username;
          notesAPIService.updateNote( note.nid, note, this.$router)
            .catch((error) => {
              this.errors.push(error);
            });
        }
        this.previousStaff = note.staff;// cache previous staff for handling changes
        this.note = note;
        this.noteClean = JSON.parse(JSON.stringify(note));
        this.noteLoading = false;
        firmsAPIService.getSingleFirm(note.fid, this.$router).then((firm) => {
          this.firm = firm;
          this.fid = firm.fid;// used for fileupload
          if (!this.dialog) document.title=`${firm.fid}  ${firm.sort} - ${firm.producer}/${firm.solicitor}/${firm.agent}`;
          this.getDataForEmail();
          this.$refs.editFiles.getAllFiles(note.fid, this.note.nid);
        })
          .catch((error) => {
            this.$refs.errorDialog.showError({
              errorMessage: 'An error occurred while trying to retrieve the firm. ' +
                'If the problem persists please notify the development team with a detailed ' +
                'description of the error.',
              forDevelopers: error
            })
          });
        })
          .catch((error) => {
            this.$refs.errorDialog.showError({
              errorMessage: 'An error occurred while trying to retrieve the note. ' +
                'If the problem persists please notify the development team with a detailed ' +
                'description of the error.',
              forDevelopers: error
            })
          });
      setTimeout(() => { this.$nextTick(() => this.adjustNoteBodyHeight()) }, 100);
    },

    destroyed() {
      window.removeEventListener("click",  this.adjustNoteBodyHeight);
      window.removeEventListener("keydown",this.adjustNoteBodyHeight);
      window.removeEventListener("resize", this.adjustNoteBodyHeight);
    },

    methods: {
      adjustNoteBodyHeight() {// calculate offset to make note body (ckeditor/textarea/iframe) screen height responsive
        this.adjustPolicyFieldWidth(); // also make policy field screen width responsive
        let noteFilesOffset = window.innerHeight > 1000 ? 300
            : window.innerHeight < 700 ? 100 : ((window.innerHeight-700)/ 1.5)  + 100;// calculate noteFilesOffset
        let offset = this.$refs.aboveNoteBodyHeight.offsetHeight + noteFilesOffset;// offset to subtract from 100vh
        this.dialog ? offset += 40 : '' ;// dialog is shorter than vh, so increase offset to reduce height of note body
        this.notebodyoffsetcssvar = { '--notebodyoffset': `${offset}px` };// set the css variable
        this.rows = (window.innerHeight - offset + 35 ) / 21;// calculate rows for textarea
      },

      adjustPolicyFieldWidth() {// make policy field width responsive
        let letters = document.querySelector('.letters');
        if (!!letters) this.policyFieldWidth = `${letters.offsetWidth - 100}px`;
      },

      checkBeforeSend() {
        // update re, in case it wasn't updated - user-entered value requires return
        // then check that re field is not empty and that this note is still in the name of username
        this.$refs.reField.$el.click();
        this.$nextTick(() => {
          const interval = setTimeout(() => {
            if (!!this.note.re) {
              notesAPIService.getNote(this.id, this.$router).then((note) => {// use id to get note and firm
                if (note.staff !== this.username) this.intervened = true;
                this.confirmEmailDialog = true;
              });
            }
          }, 100)
        });
      },

      click(area) {// expand/collapse area/s @click other area/s - areas: editor, note, recipients, comments, prep
        this.expandRecipients = area === 'recipients' ? true : false;
        if (area !== 'comments') this.expandComments = false;// close comments if click elsewhere - need editor space
      },

      convertToHtml() {
        this.note.html = true;
        this.note.note = this.note.note.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/�/g, "'");// � --> '
      },

      debounceWrapper(name) {
        if (this.debouncer) {
          this.debouncer.cancel();
        }
        this.debouncer= _.debounce(async() => {
          const people = await this.fetchPeople(name);
          this.people = _.unionBy(people, this.people, 'plid');
        }, 500);
        this.debouncer();
      },

      async fetchPeople(name) {
        let people = await peopleAPIService.findPeopleByName(name, this.$router);
        const peopleRecipients = people.filter(person => person.given || person.surname).map((person) => {
          person.text = person.sort ? `${person.sort}: ${person.given} ${person.surname}` :
              `${person.given} ${person.surname}`;
          person.dropdownText = person.text;
          //if there is a salutation add it to selected recipient, and bold it in dropdown text
          if (person.salutation && person.salutation.trim().length) {
            person.text += ` (${person.salutation})`;
            person.dropdownText += ` (<b>${person.salutation}</b>)`;
          }
          person.dropdownText += ` <font color=blue>${person.email ? person.email : ''}</font>`;// dropdown text adds email
          return person;
        });
        return peopleRecipients;
      },

      filesArrayUpdated(filesArray) {
        this.filesArray = filesArray;
      },

      getDataForEmail() {
        usersAPIService.getActiveUsers(this.$router).then((data) => {//for checking edit rights
          if (data) {this.forStaff = data;}
        });
        //get Items for note editing w listname, sort, nameonly, longonly, sortorder
        listsAPIService.getList('nstatus', "1,2", true, false,
          'DESC', 'short', null, this.$router).then((data) => {
          if (data) this.statusItems = data;
        });
        listsAPIService.getList('ntype',"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
          true, false, 'ASC', 'sort', null, this.$router).then((data) => {
          if (data) this.typeItems = data;
          if (!this.groups.includes('admin')) {// if user doesn't have admin override, disable some type options
           for (let type of this.typeItems) type.disabled = ['fax in', 'email in', 'web in'].includes(type.text);
          }
        });
        listsAPIService.getList('re',
            "", false, true, 'ASC', 'short', null, this.$router).then((data) => {
          if (data) for (let re of data) this.reItems.push(re.text);
        });
      },

      getPolicies() {
        this.policyLoading = true;
        firmsAPIService.getFirmPolicies(this.firm.fid, this.$router).then((data) => {
          if (data) {
            let policies = data;
            this.policies = [];
            this.policy = {};
            this.policyClean = this.policy;
            for (let policy of policies) {
              if (['Book','dncLost','Lost','Past','Tail'].includes(policy.status)) {
                // if there is a pid for this note, then show that one
                // re line should be like: app entry Am Bankers 12-15-2015
                let type = policy.type ? `${policy.type}-` : '';// prevent '-' from being displayed in dropdown
                policy.text = `${policy.carrier || ''} (${type}${policy.status}) ${policy.expiry || ''}`;
                if (policy.pid === this.note.pid) {
                  this.policy = policy;// pre-populate policy field if match
                  this.policyClean = policy;// pre-populate policyClean field if match
                }
                this.policies.push(policy);
              }
            }
            this.policyLoading = false;
          }
        });
      },

      getRecipients() {//get recipients and all the other data for prepping email
        let id = this.note.nid;
        let fid = this.firm.fid;

        // get letters/template forms and get policies -
        notesAPIService.getAllForms(fid, this.$router).then((data) => {
          if (!!data) {
            this.templates = data[0];
            this.templates = this.templates.map((template) => {
              template.text = `${template.type}-${template.sort}: ${template.title}`;
              return template
            });
            this.template = this.templates.find(form => form.frid === this.note.frid) || {}
          }
        });

        this.getPolicies(true);

        // get Items for from field w listname, sort, nameonly, longonly, sortorder
        listsAPIService.getList('name', "1", true, false,
          'ASC', 'sort', null, this.$router).then((data) => {
          if (data) {
            this.fromArray = [];
            for (let fromOption of data) this.fromArray.push(fromOption.value);
            //check if array includes this.note.signer
            if (!!this.note.signer && this.fromArray.includes(this.note.signer)) {
              this.from = this.note.signer;
            }
          }
        });
        // populate 'to' field, but replace with recipient if found below
        if (!!this.note.plid) {
          this.to.plid = this.note.plid;
          peopleAPIService.getPerson(this.to.plid, this.$router).then(person => {
            if ('given' in person) {
              this.to.plname = person.given;
              this.to.given = person.given;
              this.to.surname = person.surname;
            }
          });
        }
        recipient.list(id, this.$router).then((data) => {
          if (!!data) {
            this.recipients = data;
            for (let recipient of this.recipients) {
              if (recipient.cc === true) {
                if (!!recipient.person) {
                  this.cc.push({
                    plid: recipient.plid,
                    given: recipient.person.given,
                    surname: recipient.person.surname,
                    plname: recipient.person.given
                  });
                }
              }
              if (recipient.bcc === true) {
                if (!!recipient.person) {
                  this.bcc.push({
                    plid: recipient.plid,
                    given: recipient.person.given,
                    surname: recipient.person.surname,
                    plname: recipient.person.given
                  });
                }
              }
            }
          }
          this.warningDisabled = false;
        });

        firmsAPIService.getFirmPeople(fid, this.$router).then(async (people) => {
          const peopleWithEmail = people.filter(person => person.email && person.email.trim().length);
          people = peopleWithEmail.map((person) => {
            person.text = `${person.given} ${person.surname}`;
            person.dropdownText = ` - - ${person.text}`;
            //if there is a salutation add it to selected recipient, and bold it in dropdown text
            if (person.salutation && person.salutation.trim().length) {
              person.dropdownText += ` (<b>${person.salutation}</b>)`;
              person.text += ` (${person.salutation})`;
            }
            person.dropdownText += ` <font color=blue>${person.email}</font>`;// dropdown text adds email
            return person
          });
          // after formatting, concat api results from getContactsByFid & getFirmPeople for recipient fields
          let savedPeoplePromise= [...this.bcc, ...this.cc];
          if (this.to && this.to.plname) {
            savedPeoplePromise.push(this.to);
          }
          const savedPeople = (await Promise.all(savedPeoplePromise.map(({plname}) => plname && this.fetchPeople(plname)))).flat();
          people = _.unionBy(savedPeople, people, 'plid');
          this.firmPeople = people;
       });
        this.editEmail = true;
        this.expandRecipients = true;
      },

      pasteFormConfirm() {
        if (!this.template.text) return;
        this.note.note ? this.confirmPasteDialog = true : this.pasteForm();
      },

      pasteForm() {
        // need to paste into body in plain text, for (letters) template form to format properly
        let inCkeditor = !!this.note.html;// flag which editor to return to after paste
        if (this.template) {
          if (this.template.body) {
            this.revertToPlainText();
            this.note.note = this.template.body;
          }
          if (this.template.re) this.note.re = this.template.re;
          // after pasting template need to convert to html, for template form to format properly
          this.convertToHtml();
          if (!inCkeditor) this.revertToPlainText();// return to the editor user was in before paste: ckeditor/textarea
        }
        // set note.re to form re line
        // set note.note to form body, and note.re to form re
      },

      pastePolicy() {// append policy info to re line with optional polnum
        if (this.policy && !this._.isEmpty(this.policy) ) {
          let expiryWithParen = '';
          if (this.policy.expiry) expiryWithParen = ` (${this.policy.expiry}) `;
          let polnum = this.pastePolnum && this.policy.polnum ? this.policy.polnum : '';
          this.note.re = `${this.note.re || ''} ${expiryWithParen} ${this.policy.insured || ''} ${polnum}`
        }
      },

      revertToPlainText() {
        this.note.html = false;
        this.note.note = this.note.note.replace(/<br\s?\/?>|<\s?\/\s?p>/gm, '\n').replace(/<p\s?>/gm, '')
            .replace(/&nbsp;/gm, ' ').replace(/�/gm, "'");
      },

      saveEmail(to, from, cc, bcc) {//save and send, or just save a note/email
        // api to send/save this email as a note, so it can be sent later,
        // FIRST saveWholeNote with to and from values
        // then destroy old recipients
        // then create the newly selected recipients
        // then send email if sending = true
        let id = this.note.nid;
        let note = this.note;
        // after return from destroy old recipients, then create new recipients
        recipient.destroy(id, this.$router).then((data) => {
          let recips = [];//assemble recipients to send to recipient.create
          recips.push({plid: to.plid, nid: id, to: true});
          for (let i = 0; i < this.cc.length; i += 1) {//prep all cc: recipients
            recips.push({plid: cc[i].plid, nid: id, cc: true});
          }
          for (let i = 0; i < this.bcc.length; i += 1) {//prep all bcc: recipients
            recips.push({plid: bcc[i].plid, nid: id, bcc: true});
          }
          return recipient.create(id, recips, this.$route).then((data) => {
            this.updateReThenSaveWholeNote(id, note); // save updated note object
            if (this.sending === true) {// send email if sending === true
              this.emailSending = true;
              let fileLoids = [];// create array of file loids, if sending as attachments only include if it has extension
              for (let file of this.filesArray) {
                if (this.sendAsAttachments === false || this.$_hasExtension(file.filename)) fileLoids.push(file.loid);
              }
              // Get long name for signature block using getList:
              listsAPIService.getList('name', "1", true, false, 'ASC', 'sort', true, this.$router).then((list) => {
                this.longName = list.find(o => o.text === from).long;
                // get email by username for signature block at bottom of email
                usersAPIService.getEmailByUsername(from, this.$router).then((user) => {
                  let emailAddress = '';
                  if (user) {
                    emailAddress = user.person ? user.person.email : '';
                    const email = {
                      loids: fileLoids,
                      username: from,
                      attach: this.sendAsAttachments,
                      subject: this.note.re,
                      body: this.styledNote + this.appendClosing + this.longName + this.appendContact + emailAddress
                          + this.appendLicenseLink + this.appendDisclaimer,
                      fid: this.firm.fid,
                    };
                    emailer.sendEmailToNote(id, email, this.$router).then((response) => {
                      this.emailSending = false;
                      this.emailSuccess = true;
                      const interval = setTimeout(() => {
                        // refresh the note to show the backend updates to date, type & status
                        notesAPIService.getNote(this.id, this.$router).then((note) => {
                          this.note = note;
                          this.noteClean = JSON.parse(JSON.stringify(note));
                          this.$nextTick(() => this.$refs.editFiles.getAllFiles(note.fid, id));
                        });
                        this.sending = false;
                      }, 3000)
                    })
                    .catch((error) => {
                      this.emailError = true;
                      this.sending = false;
                      this.$refs.errorDialog.showError({
                        errorMessage: 'An error occurred while trying to send the email. ' +
                          'If the problem persists please notify the development team with a detailed ' +
                          'description of the error.',
                        forDevelopers: error.response.data.message
                      })
                    });
                  } else {
                    this.invalidFrom = true;
                    this.sending = false;
                    this.emailSending = false;
                  }
                });
              });
            }
          })
          .catch((error) => {
            console.log('ERROR: ', error);
          })
        });
      },

      saveWholeNote(nid, note) {
        // FIRST prepare comment/task field - prepend new task to old
        // NEXT check if note taken out of user's name, add comment, set notUser flag, alert user
        // SAVE/UPDATE NOTE
        let noteToSave = note;
        if (this.newTask) {// prepend new task to old
          let date = moment(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A');
          let comment = `${date}  (${this.username})  - ${this.newTask}`;
          noteToSave.tasks = noteToSave.tasks ? `${comment}\n\n${noteToSave.tasks}` : comment;
          this.newTask = '';
        }
        if (note.staff !== this.previousStaff) {// note being reassigned - add comment & update last/closer
          let date = moment(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A');
          let comment = `${date}  (${this.username}) - `;
          if (this.previousStaff !== this.username) comment = `${comment} took from ${this.previousStaff}`;
          if (this.previousStaff !== this.username && note.staff !== this.username) comment = `${comment} and`;
          if (note.staff !== this.username) comment = `${comment} gave to ${note.staff}`;
          noteToSave.tasks = noteToSave.tasks ? `${comment}\n\n${noteToSave.tasks}` : comment;
          noteToSave.closer = this.previousStaff;
        }
        if (!!this.from) noteToSave.signer = this.from;
        if (this.to && this.to.plid || this.to.plid === 0) {
          noteToSave.plid = this.to.plid;
          noteToSave.to = this.to.plid;
        }
        return notesAPIService.updateNote( nid, noteToSave, this.$router).then(() => {
          // files now connected at the moment selected or re-ordered instead of when saving note
          if (this.sending === false) this.draftSuccess = true;
          this.notUser = note.staff !== this.username;// set flag to block editing
          this.previousStaff = noteToSave.staff;
          this.getPolicies(false);
          if (this.notUser) setTimeout(() => { this.changedForStaffField = true }, 1000);
          this.noteClean = JSON.parse(JSON.stringify(note));
          this.$emit('refresh');
        });
      },

      updateReThenSaveWholeNote(nid, note) {
        // updates re, in case it wasn't updated - user-entered value requires return
        this.$refs.reField.$el.click();
        this.$nextTick(() => {
          const interval = setTimeout(() => {
            this.saveWholeNote(nid, note);
          }, 100)
        });
      },
    },

  }
</script>
<style>
  .bcc {
    padding: 2px 3px; 
    border-width: .5px;
    border-style: solid;
    border-radius: 5px;
    background-color: whitesmoke;
  }
  /*responsive height for note body - ckeditor or iframe*/
  .body-responsive-height .ck-editor__editable {
    min-height: calc(100vh - var(--notebodyoffset));
    max-height: calc(100vh - var(--notebodyoffset));
  }
  .body-responsive-height {
    min-height: calc(100vh - var(--notebodyoffset) + 73px);
    max-height: calc(100vh - var(--notebodyoffset) + 73px);
  }
  .compactTextarea .theme--light.v-input:not(.v-input--is-disabled) textarea {
    line-height: 1.3rem;
  }
  .denseTextarea.v-textarea textarea {
    line-height: 12px;
  }
  div.line:hover {
    border-bottom: 1px solid rgba(0,0,0,.9);
  }
  .file.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    margin-bottom: -4px;
  }
  .line {
    /*style datetime to coordinate with autocompletes */
    padding-bottom: 0.3px;
    margin-top: 8.2px;
    border-bottom: 1px solid rgba(0,0,0,.3);
    width: 100%;
  }
  .recipients-style {
    cursor: pointer;
    margin: -2em 16px 16px;
    padding: 2em 0 .2rem 0;
    position: relative;
    z-index: 7;
  }
  .smoke {
    background-color: whitesmoke;
  }
  .v-application--is-ltr .v-text-field__prefix {
    margin-bottom: -3px;
  }
</style>

