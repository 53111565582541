<template>
  <div>
    <v-card class="mt-n3">
      <v-card-title class="mr-4">
        <v-tooltip bottom style="z-index: 99" class="py-0 pl-0 pr-2 mt-2">
          <template v-slot:activator="{ on }">
            <v-btn x-small fab @click="getFirmFiles(fid)" v-on="on"
                   class="px-3" elevation="5"><v-icon>mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Refresh Files</span>
        </v-tooltip>
        <v-spacer/>
        <v-row class="ml-2 mt-n1">
          <v-text-field :value="search" @change="v => search = v"
                        append-icon="mdi-magnify"
                        hint="Type search & press return" persistent-hint
                        clearable @click:clear="search = ''" dense
                        class="mt-n1 mb-n2"></v-text-field>
          <FirmFilesUpload class="mt-n1" v-bind="{ fid }" @filesUploaded="filesUploaded"/>
        </v-row>
      </v-card-title>
    </v-card>
      <v-data-table
          class="elevation-2"
          :class="[!notPopup ? 'popupLargeTableNoFooter' : 'largeSizeTableNoFooter']"
          dense
          fixed-header
          :headers="colHeaders"
          hide-default-footer
          :items="files"
          :items-per-page="-1"
          :loading="loading"
          loading-text="Data loading... Please wait..."
          multi-sort
          :mobile-breakpoint="0"
          :search="search"
          :sort-by="['dated']"
          :sort-desc="[true]"
          :style="$_cssVars"
      >
        <template v-slot:item.filename="{ item }">
          <v-lazy>
            <div>
              <v-tooltip left v-if="item.filename && item.filename.length > cutoff">
                <template v-slot:activator="{ on }">
                  <div v-on="on">{{ item.filename ? item.filename.slice(0, cutoff - 3) + '...' : '' }}</div>
                </template>
                {{ item.filename ? item.filename : '' }}
              </v-tooltip>
              <span v-else>{{ item.filename || '' }}</span>
            </div>
          </v-lazy>
        </template>
        <template v-slot:item.content_type="{ item }">
          <v-lazy>
            <div>
              <v-tooltip bottom nudge-top="33" v-if="item.content_type && item.content_type.length > cutoff">
                <template v-slot:activator="{ on }">
                  <div v-on="on">{{ item.content_type ? item.content_type.slice(0, cutoff - 3) + '...' : '' }}</div>
                </template>
                {{ item.content_type || '' }}
              </v-tooltip>
              <span v-else>{{ item.content_type ? item.content_type : '' }}</span>
            </div>
          </v-lazy>
        </template>
        <template v-slot:item.file_description="{ item }">
	        <v-lazy>
            <v-row>
              <v-edit-dialog :return-value="item.file_description">
                <v-tooltip bottom nudge-top="25">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      class="ml-4 mr-3 pr-3 py-0 file noUnderline"
                      style="margin-top: 2px; margin-bottom: -14px"
                      dense :rules="rules"
                      @keyup.enter="save(item, 'description')"
                      :readonly="!groups.includes('admin') && (!$_can('firm-edit')
                        || item.file_type_description === 'Form')"
                      :style="{width: ($vuetify.breakpoint.name === 'xs' ? '200px' : '36vw')}"
                      :value="item.file_description" @change="v => item.file_description = v"
                    >
                      <template v-slot:prepend>
                        <button-tip a icon iname="mdi-download" tip="Download"
                                istyle="margin-top:2px;color:blue" :href="`${url}/download/${item.loidfid}`" xsmall/>
                      </template>
                    </v-text-field>
                  </template>
                  <span v-if="!groups.includes('admin') && (!$_can('firm-edit') || item.file_type_description === 'Form')">{{item.file_description}}</span>
                  <span v-else>Press Return to Save Edit</span>
                </v-tooltip>
              </v-edit-dialog>
            </v-row>
          </v-lazy>
        </template>
        <template v-slot:item.dated="{ item }">
          <v-lazy>
            <span>
              {{ moment(item.dated, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A')}}
            </span>
          </v-lazy>
        </template>
        <template v-slot:item.file_type_description="{ item }">
	  <v-lazy>
            <v-edit-dialog :return-value="item.file_type_description">
              {{ item.file_type_description }}
              <template v-slot:input v-can="'firm-edit'">
                <v-autocomplete
                  class="px-2 mb-6"
                  :items="types"
                  persistent-hint hint="Press Return to Save"
                  @keyup.enter="save(item, 'type')"
                  v-model="item.file_type_description"
                ></v-autocomplete>
              </template>
            </v-edit-dialog>
	  </v-lazy>
        </template>
    </v-data-table>
  </div>
</template>

<script>
import FirmFilesUpload from "./FirmFilesUpload";
import { FileobjectsAPIService } from '@/servicehandlers/FileobjectsAPIService';
import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';

const apiService = new FirmsAPIService();
const file_objects = new FileobjectsAPIService();

export default {
  name: "FirmFilesTable",
  props: {
    firm: Object,
    notPopup: Boolean,
    default() {
      return {};
    }
  },
  components: { FirmFilesUpload },
  data() {
    return {
      colHeaders: [
        {value:'filename',             text:'Filename',    width: '180px', align: 'start'},
        {value:'content_type',         text:'Content Type',width:  '45px', align: 'start'},
        {value:'file_description',     text:'Description', width: '460px', align: 'start'},
        {value:'file_type_description',text:'Type',        width:  '80px', align: 'start'},
        {value:'dated',                text:'Dated',       width: '180px', align: 'start'},
      ],
      fid: this.firm.fid,
      files: [],
      groups: [],
      info: [],
      loading: false,
      rules: [
        (v) => !!v || 'Description is required to upload a file',
      ],
      search: '',
      url: process.env.VUE_APP_UW_URL,
    }
  },

  created() {
    this.groups = this.$store.getters.groups;
    this.getFirmFiles(this.fid);
  },

  computed: {
    types() {
      let canEdit = this.groups.includes('admin') || this.$_can('firmfile-override');
      let types = ['Admin', 'Original', 'Other'];
      if (canEdit) types = types.concat('Form');// if user canEdit show 'Form' as an option
      return types;
    },
    cutoff() {
      return !this.notPopup ? 15 : 30;
    },
  },

  methods: {
    getFirmFiles(id) {
      this.loading = true;
      let FID = id.toString();
      apiService.getFirmFiles(FID, this.$router).then((data) => {
        this.loading = false;
        // if user is not admin, just keep files where file_type_description is not admin
        if (!this.groups.includes('admin')) {
          data = data.filter(file => file.file_type_description !== 'Admin');
        }
        this.files = data;
      })
    },

    getType(description) {// 1-Admin 3-Form 6-Other 17-Original
      switch(description) {
        case 'Admin':
          return 1;
          break;
        case 'Form':
          return 3;
          break;
        case 'Other':
          return 6;
          break;
        case 'Original':
          return 17;
          break;
        default:
          return 6;
      }
    },

    save(file, field) {
      const my_file_object = field === 'description' ? { file_description: file.file_description }
        : { file_type: this.getType(file.file_type_description) };
      file_objects.updateFileObject(file.loid, my_file_object, this.$router)
        .then((response) => {
          // this.getFirmFiles(this.fid);// use refresh button instead, for faster multiple edits
        });
    },

    filesUploaded(files) {
      this.getFirmFiles(this.fid);
    },
  },

}
</script>

<style scoped>

</style>


