<template>
  <v-container fluid grid-list-md pa-1 pa-lg-2 mb-7>
    <v-layout row wrap>
      <v-flex pa-1 pa-lg-2
              v-for="tile in tiles"
              :key="tile.title"
              v-bind="{ [`xs${tile.xs}`]: true, [`lg${tile.md}`]: true }"
      >
        <component @expandedLengthChanged="expandedLengthChanged" :expandedTable="expanded[tile.items]"
                   :is="tile.component" :items="tile.items" @resize="resetTableHeights"
                   style="display: flex; flex-direction: column;  height: 100%"
         ></component>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import HomeHours from './home/HomeHours.vue';
  import HomeNotes from './home/HomeNotes.vue';

  export default {
    name: 'home',
    components: {
      HomeHours,
      HomeNotes,
    },

    data: () => ({
      expanded: {// height of table is expanded? pass this to child component
        'News': false,
        'Events': false,
        'Appts': false,
      },
      expandedRow: {// one or more rows expanded in each table?
        'News': false,
        'Events': false,
        'Appts': false,
      },
      tiles: [
        {
          md: 12,
          xs: 12,
          component: 'HomeNotes',
          items: 'News'
        },
        {
          md: 6,
          xs: 12,
          component: 'HomeNotes',
          items: 'Events'
        },
        {
          md: 6,
          xs: 12,
          component: 'HomeNotes',
          items: 'Appts'
        },
        {
          md: 12,
          xs: 12,
          component: 'HomeHours',
          items: 'Hours'
        }
      ]
    }),

    methods: {
      expandedLengthChanged(expandedLength, items) {
        // determine if items table has at least one row expanded, then reset table heights accordingly
        this.expandedRow[items] = expandedLength > 0;// does items table have at least one expanded row?
        this.resetTableHeights(expandedLength, items);
      },

      resetTableHeights(expandedLength, items) {// determine which should have expanded height
        let smallScreen =  ['xs','sm','md'].includes(this.$vuetify.breakpoint.name);
        if (smallScreen) {
          this.expanded[items] = expandedLength > 0;
          return;
        }
        if (items === 'News') {
          this.expanded['News'] = expandedLength > 0;
        }
        if (['Events', 'Appts'].includes(items)) {// if either has a row expanded set both tables to be expanded;
          this.expanded['Events'] =  this.expandedRow['Events'] || this.expandedRow['Appts'];
          this.expanded['Appts'] =  this.expandedRow['Events'] || this.expandedRow['Appts'];
        }
      },

    },
  };
</script>
