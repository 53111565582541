import CommonAPIService from './CommonHandler';
import _ from 'lodash';

export class NotesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
    this.notesFields = [
      'nid', 'stamp', 'ostamp', 'cstamp', 'fid', 'pid', 'plid', 'direct',
      'type', 'status', 'opener', 'closer', 'signer', 'staff', 'dated', 'due', 're',
      'note', 'frid', 'taken', 'tasks', 'html', 'to'
    ]
  }

  getNote(id, router) {
    const url = `/api/notes/${id}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getNoteFiles(id, router) {
    const fields = 'loid,loidfid,file_description,filename,size,content_type,file_objects.dated,sort_index,f_type';
    const url = `/api/notes/${id}/files?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  connectFiles(id, loids, router) {
    const url = `/api/notes/${id}/files`;
    return this.CommonAPIService.postCall(url, { loids }, router);
  }

  disconnectFiles(id, loids, router) {
    const url = `/api/notes/${id}/files?loids=${loids.join(',')}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  updateFiles(id, loids, router) {
    const url = `/api/notes/${id}/files`;
    return this.CommonAPIService.putCall(url, loids, router);
  }

  getAllForms(router) {
    const url = '/api/notes/forms';
    return this.CommonAPIService.getCall(url, router);
  }

  getNextNote(userid, router) {
    const url = `/api/notes/jobs/next?userid=${userid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  updateNote(id, note, router) {
    const extraFields = Object.keys(note).filter(key => !this.notesFields.includes(key));
    extraFields.push('dated');// this field is no longer accepted by backend
    note = _.omit(note, extraFields);
    const url = `/api/notes/${id}`;
    return this.CommonAPIService.putCall(url, note, router);
  }

  createNote(note, router) {
    const url = '/api/notes';
    return this.CommonAPIService.postCall(url, note, router);
  }

  deleteNote(id, router) {
    const url = `/api/notes/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  searchNotes(search, router) {
    const url = '/api/notes/search';
    return this.CommonAPIService.postCall(url, search, router);
  }

  getAllJobs(router) {
    const url = '/api/notes/jobs?job=all';
    return this.CommonAPIService.getCall(url, router);
  }

  /**
   * Get jobs and filter by given parameters
   * @param {object | null} parameters - The query parameters for filtering and ordering the results
   * @param {string} [parameters.usernames] - Comma-separated list of usernames to search for jobs belonging to the given users
   * @param {string} [parameters.job] - The job type to filter by ['users', 'ticklers', 'retries', 'others', 'solicit', 'all'], default is all
   * @param {string} [parameters.status] - The status of the job note to filter by (ex. cancel, xtreme, normal, ...)
   * @param {string} [parameters.type] - The type of the job note to filter by (ex. retry, news, memo, ...)
   * @param {string} [parameters.re] - Looks for notes where re matches or contain the passed re.
   * @param {boolean} [parameters.withACLs] - Default is true, get jobs for other users if the given usernames are in their allow list.
   * @param {Route} router - Object that contains information about the current route.
   * @example { usernames: 'john,doe', job: 'users', status: 'normal', type: 'memo', re: 'test'}
   * @returns {Promise<object[]>} - A promise consisting of an array of job objects matching the specified parameters
   */
  getJobs(parameters, router) {
    const queryParams = new URLSearchParams(parameters ?? {}).toString();
    const url = `/api/notes/jobs?${queryParams}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getTicklersJobs(username, router) {
    const url = `/api/notes/jobs?usernames=${username}&job=ticklers`;
    return this.CommonAPIService.getCall(url, router);
  }

  getRetriesJobs(username, router) {
    const url = `/api/notes/jobs?usernames=${username}&job=retries`;
    return this.CommonAPIService.getCall(url, router);
  }

  getOthersJobs(username, router) {
    const url = `/api/notes/jobs?usernames=${username}&job=others`;
    return this.CommonAPIService.getCall(url, router);
  }

  getSolicitJobs(router) {
    const url = `/api/notes/jobs?job=solicit`;
    return this.CommonAPIService.getCall(url, router);
  }

  getNotesRecipients(nid, router, fields = {firms: '', people : ''
                     , contacts : '', recipients : ''}) {
    let query = `firms_fields=${fields.firms}&people_fields=${fields.people}`;
    query += `&contacts_fields=${fields.contacts}&recipients_fields=${fields.recipients}`
    const url = `/api/notes/${nid}/recipients?${query}`;
    return this.CommonAPIService.getCall((url, router));
  }

  getJobsForUsernames(usernamesArr, router) {
    const username = usernamesArr.join(',');
    const url = `/api/notes/jobs?username=${username}`;
    return this.CommonAPIService.getCall(url, router);
  }

  updateNoteAsRead(nid, router) {
    const url = `/api/notes/${nid}/readers`;
    return this.CommonAPIService.postCall(url, {}, router);
  }

  getRead(nid, router) {
    const fields = 'readers.stamp,readers.uname,uname,ostamp,cstamp,staff';
    const url = `/api/notes?fields=${fields}&nid=${nid}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getAllNewsNotes(router) {
    const fields ='cstamp,nid,note,re,opener,closer,signer,staff,status,tasks,type';
    const limit = 4000;
    const orders = 'cstamp';
    const directions = 'DESC'
    const url = `/api/notes?fields=${fields}&withFirmSort=true&type=news&orders=${orders}&directions=${directions}&limit=${limit}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getNewsNotesByUsername(username, router) {
    const fields ='cstamp,nid,note,opener,closer,re,staff,status,tasks,type';
    const url = `/api/notes?fields=${fields}&staff=${username}&type=news`;
    return this.CommonAPIService.getCall(url, router);
  }

  getCalendarNotesByType(type, router) {
    const fields ='cstamp,nid,note,re,staff,type';
    const url = `/api/notes?fields=${fields}&type=${type}&withFuturecstamp=true`;
    return this.CommonAPIService.getCall(url, router);
  }

  /**
   * Returns the count of active jobs and the count of extreme jobs for each active user
   * @param {object | null} parameters - The query parameters for filtering and ordering the results.
   * @param {string} [parameters.staff] - Comma-separated list of staff usernames to filter the job counts.
   * @param {string} [parameters.orders] - Comma-separated list to specify the ordering of the results.
   * @param {string} [parameters.directions] - Comma-separated list to specify the direction of the ordering.
   * @param {Route} router - object that contains information about the current route.
   * @example getJobsCountByUsers({staff: 'user1,user2', orders: 'staff'}, this.$route);
   * @example getJobsCountByUsers(null, this.$route);
   * @returns {Promise<{staff: string, activeJobs: number, extremeJobs: number}[]>} - Returns a promise containing an array
   * of objects with properties `staff`, `activeJobs`, and `extremeJobs`.
   */
  getJobsCountByUsers(parameters, router) {
    const queryParams = new URLSearchParams(parameters ?? {}).toString(); // if parameter is null then default input is {}
    const url = `/api/notes/jobs/count?${queryParams}`;
    return this.CommonAPIService.getCall(url, router);
  }
}

export default NotesAPIService;
