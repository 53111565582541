<template>
  <div>
    <v-card class="mt-0 elevation-0 rounded-0 borderless">
      <v-card-title class="mt-n1">
        <button-tip btnclass="ml-n1 mt-n1 mr-3" @click="refresh = true, viewContacts()" elevation="5" fab icolor="black"
                    iname="mdi-update" tip="Refresh Contacts" xsmall/>
        <span>Contacts</span>
        <information-dialog-icon confirmClass="mr-3 title" confirmColor="secondary darken-1"
                                 tclass="headline grey lighten-2 mb-8" title="Contact Information">
          This page lists all the contact information of business entities. View information about
          specific people associated with an entity by opening a contact in a dialog or popup window.
        </information-dialog-icon>
        <v-spacer/>
        <v-text-field
          append-icon="mdi-magnify"
          class="mt-n3 mb-n7 mx-2"
          clearable
          dense
          label="Search"
          single-line
          style="width:30vw"
          v-model="search"
        ></v-text-field>
        <button-tip btnclass="mt-n1" color="secondary" elevation="5" xsmall fab icolor="white" iname="mdi-plus"
                    @click="contactPopup('Add')" tip="Add Contact" v-can="'contact-edit'"/>
      </v-card-title>

        <v-data-table
          class="px-0 mx-1 mt-n3 mb-2 pb-0 contactsTable elevation-2"
          @click:row="rowClick"
          :dense="dense"
          fixed-header
          :headers="contactHeaders"
          hide-default-footer
          item-key="cid"
          :items-per-page="-1"
          :items="contacts"
          :loading="state === 'loadingContacts'"
          loading-text="Loading... Please wait"
          :mobile-breakpoint="0"
          multi-sort
          :search="search"
          single-select
          :sort-by="['sort']"
        >
          <template v-slot:item.actions="{ item }">
            <v-lazy>
              <div>
                <button-tip btnclass="mx-1" @click="cidToEdit = item.cid, contactDialog = true" icon
                            iname="mdi-open-in-app" small tip="Contact Dialog"/>
                <button-tip @click="contactPopup(item.cid)" icon iname="mdi-open-in-new" tip="Contact Popup"/>
<!--                <button-tip @click="deleteContact(item)" icolor="red lighten-2" icon iname="mdi-trash-can-outline"-->
<!--                  tip="Delete Contact" v-can="'contact-override'"/>-->
              </div>
            </v-lazy>
          </template>
          <template v-slot:item.name="{ item }">
            <v-lazy>
              <div v-html="item.name"></div>
            </v-lazy>
          </template>
          <template v-slot:item.type="{ item }">
            <v-tooltip right v-if="item.type && item.type.length > 30">
              <template v-slot:activator="{ on }">
                <div v-on="on">{{ item.type ? item.type.slice(0, 25) + '...' : '' }}</div>
              </template>
              {{ item.type || '' }}
            </v-tooltip>
            <div v-else>{{ item.type || '' }}</div>
          </template>
          <template v-slot:item.phone="{ item }">
            <div v-if="item.phone">
              {{ item.phone.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}</div>
          </template>
          <template v-slot:item.fax="{ item }">
            <div v-if="item.fax">
              {{ item.fax.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}</div>
          </template>
          <template v-slot:item.address="{ item }">
            <v-lazy>
              <div class="compact-address" v-html="item.address"></div>
            </v-lazy>
          </template>
        </v-data-table>
    </v-card>

    <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" max-width="1200" persistent :retain-focus="false" v-model="contactDialog">
      <v-card>
        <div>
          <edit-contact
            :contact-dialog.sync="contactDialog"
            :dialog="true"
            :id="cidToEdit"
            @close-dialog="contactDialog = false"
            @input="contactDialog = false"
            :refresh.sync="refresh"
            @view-contacts="viewContacts()"
          />
        </div>
      </v-card>
    </v-dialog>

    <ErrorDialog ref="errorDialog"/>
    <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='ContactsSearch'"></snack>
  </div>
</template>

<script>
import { ContactAPIService } from "@/servicehandlers/ContactAPIService";
import EditContact from "./EditContact";

const contactAPIService = new ContactAPIService();

export default {
  name: 'ContactsSearch',
  components: { EditContact },
  data() {
    return {
      cidToEdit: '',
      contactDialog: false,
      contactHeaders: [
        { value: 'actions',text: 'Actions',  width: '140px', sortable: false, align: 'center' },
        { value: 'sort',   text: 'Sort Name',width: '130px' },
        { value: 'name',   text: 'Name',     width: '320px' },
        { value: 'type',   text: 'Type',     width: '120px' },
        { value: 'phone',  text: 'Phone',    width: '130px' },
        { value: 'fax',    text: 'Fax',      width: '130px' },
        { value: 'address',text: 'Address',  width: '300px'  }
      ],
      contacts: [],
      dense: true, //table density
      groups: [],
      refresh: false,
      search: '',
      state: 'loadingContacts',
    };
  },

  created() {
    document.title = 'Contacts';
  },

  mounted() {
    this.groups = this.$store.getters.groups;
    this.$store.getters.userPreferences.dense
      ? (this.dense = this.$store.getters.userPreferences.dense)
      : false;
    this.viewContacts();
  },

  methods: {
    contactPopup(contactId) {
      let baseUrl = window.location.origin;
      window.open(baseUrl +  '/editContact/' +  contactId, '_blank',
          'resizeable=yes, width=' + (1200)
          + ', left=' + (500)
          + ', top=' + (64)
          + ', height=' + (760));
    },

    deleteContact(contact) {
      contactAPIService.deleteContact(contact.cid, this.$router).then((response) => {
        this.$store.dispatch('SET_SNACK', { cmpnt: 'ContactsSearch', open: true,
          msg: 'Contact has been deleted.'});
        this.refresh = true;
        this.viewContacts();
      })
        .catch((e) => {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to delete contact. This is likely because the contact is linked to another record in " +
                "the database.",
            forDevelopers: e
          })
        });
    },

    rowClick: function (item, row) {// highlights selected row
      row.select(true);
    },

    viewContacts() {
      this.state = 'loadingContacts'
      if (this.contacts.length > 0 && !this.refresh) {
          this.state = 'showContacts';
      }
      else {
          this.loading = true;
          contactAPIService.getContacts(this.$router)
              .then((contacts) => {
                  const final = contacts.map(x => (
                      {...x,
                       name: x.name1 + (x.name2 ? '<br/>' + x.name2 : ''),
                       address: (x.address1 ?  x.address1 + '<br/>' : '')
                                  + (x.address2 ? x.address2 + '<br/>': '')
                                  + (x.city ? x.city + ', ' : '')
                                  + (x.state ? x.state : '')
                                  + (x.zip ? ' ' + x.zip : '')}))
                  this.contacts = final;
                  this.refresh = false;
                  if (this.state === 'loadingContacts') this.state = 'showContacts'
              })
      }
    }
  }
};
</script>

<style>
.compact-address {
  font-size: 12px;
  line-height: 1 !important;
}
.contactsTable .v-data-table__wrapper { height: calc(100vh - 105px) !important }
</style>
