<script>
  export default {
    name: "NoteExpansion",
    props: {
      colspan: {
        required: true
      },
      files: {
        default: []
      },
      nid: {
        required: true
      },
      note: {},
      tasks: {},
      type: {},
      width: '',
    },
    render(createElement) {
      let webNote = this.type === 'web' || this.type === 'web in';
      let children = [];

      if (this.tasks) {
        let task = createElement('div', {}, [
          createElement('p', {
            attrs: {
              class: 'font-italic',
              width: this.width,
            },
          }, 'Comments: '),
          createElement('p', {
            domProps:{
              innerHTML:`<div style="width: ${this.width} !important; background-color: transparent !important">`
                + this.tasks.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/�/g, "'") + '</div>'
            }
          })
        ]);
        children.push(task);
      }

      if (this.note) {
        let note;
        if (webNote) {
          note = createElement('div', {}, [createElement('iframe', {
            attrs: {
              srcdoc: this.note,
              width: this.width,
              height: '300px'
            },
          })]);
        } else {
          note = createElement('div', {
            attrs: {
              class: 'note'
            },
            domProps:{
              innerHTML:`<div style="width: ${this.width} !important; background-color: transparent !important">` + this.note.replace(/�/g, "'") + '</div>'
            }
          });
        }

        children.push(note);
      }

      if (this.files.length) {
        let fileLinks = [];
        fileLinks.push(createElement('br'));// need space here
        this.files.forEach(file => {
            let link = createElement('a', {
              attrs: {
                href: `${process.env.VUE_APP_UW_URL}/download/${file.loidfid}`,
                width: this.width,
              },
              style: {
                color: 'blue'
              }
            }, `${file.file_description}`);
            fileLinks.push(link, createElement('br'));
        });

        let files = createElement('div', {}, fileLinks);
        children.push(files)
      }

      return createElement('td', {
        attrs: {
          style: 'background-color: #f9f9f9',// slightly lighter than whitesmoke to make expansions easier to spot
          class: 'py-6',
          colspan: this.colspan,
        }
      }, children);
    },
  }
</script>

<style scoped>
    .note table {
        border-collapse: collapse;
        margin: 0 auto;
        width: auto !important;
    }
    .note table, .note th, .note td, .note tr {
        border: 1px solid black !important;
    }
    .note td {
        padding: 1em;
    }
</style>
