import Vue from 'vue';
import Router from 'vue-router';
import { MeAPIService } from '@/servicehandlers/MeAPIService';
import NoPermissionToView from '@/views/NoPermissionToView';
import { store } from './store';
import About from '@/views/About';
import BindAuthorityProduction from '@/components/reports/BindAuthorityProduction';
import Bordereaux from '@/components/reports/Bordereaux';
import ClaimsSearch from '@/components/search/claims/ClaimsSearch';
import ContactsSearch from '@/components/search/contacts/ContactsSearch';
import CommissionsManager from '@/components/management/CommissionsManager';
import EditClaim from '@/components/search/claims/EditClaim';
import EditContact from '@/components/search/contacts/EditContact';
import EditFirm from '@/components/search/firms/EditFirm';
import EditNoteEmail from '@/components/search/firms/EditNoteEmail';
import EditPerson from '@/components/search/firms/EditPerson';
import EditPolicy from '@/components/search/firms/EditPolicy';
import FirmsSearch from '@/components/search/firms/FirmsSearch';
import Home from '@/views/Home.vue';
import IdlePage from '@/components/idle/IdlePage';
import Login from '@/components/Login';
import LossRun from '@/components/reports/LossRun';
import Manager from '@/components/management/Manager';
import MgaBillingManager from '@/components/management/MgaBillingManager';
import NateTester from '@/components/NateTester';
import PeopleSearch from '@/components/search/people/PeopleSearch';
import ProducerCommissionsManager from '@/components/management/ProducerCommissionsManager';
import Renewal from '@/components/reports/Renewal';
import ReportsSearch from '@/components/reports/ReportsSearch';
import Roles from '@/components/rights/Roles';
import TaxManager from '@/components/management/TaxManager';
import ViewClaim from '@/components/search/claims/ViewClaim';
import ViewFirm from '@/components/search/firms/ViewFirm';
import ViewFirmNoNote from '@/components/search/firms/ViewFirmNoNote';
import ViewPolicy from '@/components/search/firms/ViewPolicy';

Vue.use(Router);
let testRoutes = [];
if (process.env.VUE_APP_FEATURES !== 'production') {// these are the routes that are NOT ready for production, but are included for development and testing
  testRoutes = [
  {
    path: '/search/people/peopleSearch',
    name: 'PeopleSearch',
    component: PeopleSearch,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/nate',
    name: 'NateTester',
    component: NateTester,
  },
];
}

// remove the links if the user does not have rights to see them
export const router = new Router({// ready for beta/production
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Main',
      component: Home,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        public: true
      }
    },
    {
      path: '/idle',
      name: 'Idle',
      component: IdlePage
    },
    {
      path: '/no-permission',
      name: 'NoPermissionToView',
      component: NoPermissionToView,
    },
    {
      path: '/reports/bindauthority',
      name: 'BindAuthorityProduction',
      component: BindAuthorityProduction,
      meta: {
        scopes: ['bindauthority-view']
      }
    },
    {
      path: '/reports/bordereau',
      name: 'Bordereaux',
      component: Bordereaux,
      meta: {
        scopes: ['bordereau-view']
      }
    },
    {
      path: '/reports/reportsSearch',
      name: 'ReportsSearch',
      component: ReportsSearch,
      meta: {
        scopes: ['report-view']
      }
    },
    {
      path: '/search/contacts/contactsSearch',
      name: 'ContactsSearch',
      component: ContactsSearch,
      meta: {
        scopes: ['contact-view']
      }
    },
    {
      path: '/editContact/:id',
      name: 'editContact',
      component: EditContact,
      meta: {// this component is also used for viewing contact information, so contact-edit not needed
        // scopes: ['contact-edit']
      },
      props: true
    },
    {
      path: '/search/claims/claimsSearch',
      name: 'ClaimsSearch',
      component: ClaimsSearch,
      meta: {
        scopes: ['claim-view']
      }
    },
    {
      path: '/reports/Renewal',
      name: 'Renewal',
      component: Renewal,
      meta: {
        scopes: ['claim-view']
      },
    },
    {
      path: '/reports/LossRun',
      name: 'LossRun',
      component: LossRun,
      meta: {
        scopes: ['claim-view']
      },
    },
    {
      path: '/search/claims/editClaim',
      name: 'EditClaim',
      component: EditClaim,
    },
    {
      path: '/editPerson/:id',
      name: 'editPerson',
      component: EditPerson,
      // need 'contact-view' to navigate to EditPerson to view/edit contacts, members or people (they are all people)
      // after navigating to EditPerson an additional scope 'person-edit' is required to edit
      meta: {
        scopes: ['contact-view']
      },
      props: true
    },
    {
      path: '/editClaim/:id',
      name: 'editClaim',
      component: EditClaim,
      props: true
    },
    {
      path: '/viewClaim/:id',
      name: 'viewClaim',
      component: ViewClaim,
      props: true
    },
    {
      path: '/editPolicy/:id',
      name: 'editPolicy',
      component: EditPolicy,
      meta: {
        scopes: ['policy-edit']
      },
      props: true
    },
    {
      path: '/ViewPolicy/:id',
      name: 'ViewPolicy',
      component: ViewPolicy,
      props: true
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
    },
    {
      path: '/editFirm/:id',
      name: 'editFirm',
      component: EditFirm,
      meta: {
        scopes: ['firm-edit']
      },
      props: true
    },
    {
      path: '/search/firms/firmsSearch',
      name: 'FirmsSearch',
      component: FirmsSearch,
      meta: {
        scopes: ['firm-view'],
      }
    },
    {//this component wraps ViewFirm and sets noNote to true
      path: '/viewFirmNoNote/:id',
      name: 'viewFirmNoNote',
      component: ViewFirmNoNote,
      props: true,
    },
    {
      path: '/viewFirmNote/:id',
      name: 'view',
      component: ViewFirm,
      props: true,
    },
    {
      path: '/search/firms/viewFirm',
      name: 'ViewFirm',
      component: ViewFirm,
    },
    {
      path: '/editNoteEmail/:id',
      name: 'editNoteEmail',
      component: EditNoteEmail,
      props: true
    },
    {
      path: '/manage',
      name: 'Manager',
      component: Manager,
      children: [
        {
          path: 'commissions',
          name: 'CommissionsManager',
          component: CommissionsManager,
          meta: {
            scopes: ['mgacommission-manage']
          }
        },
        {
          path: 'mgaBilling',
          name: 'MgaBillingManager',
          component: MgaBillingManager,
          meta: {
            scopes: ['mgabilling-manage']
          }
        },
        {
          path: 'producerCommissions',
          name: 'ProducerCommissionsManager',
          component: ProducerCommissionsManager,
          meta: {
            scopes: ['producercommission-manage']
          }
        },
        {
          path: 'roles',
          name: 'Roles',
          component: Roles,
          meta: {
            scopes: ['role-edit']
          }
        },
        {
          path: 'taxes',
          name: 'TaxManager',
          component: TaxManager,
          meta: {
            scopes: ['tax-manage']
          }
        },
      ]
    },
    {
      path: '/rights/Roles',
      name: 'Roles',
      component: Roles,
    },
    ...testRoutes
  ],
});

const meAPIService = new MeAPIService();
router.beforeEach(async (to, from, next) => {
  if (!router.app.$data) await Vue.nextTick();
  if (!store.state.loggedIn) { // User may have a session, but the store may not be initialized.
    try {
      await meAPIService.getUserData(router);
    } catch { // The user truly is logged out
      const isPublic = to.matched.some(({ meta }) => meta.public);
      if (isPublic) return next();
      return next({path: '/login', query: { redirect: to.fullPath }});
    }
  }

  const scopesPerRoute = to.matched.map(({ meta }) => meta.scopes);
  const authorized = scopesPerRoute.every(scopes => {
    if (!scopes) return true;
    if (!('scopes' in scopes)) return router.app.$_canAll(scopes); // Scopes is an array
    if (scopes.any) return router.app.$_canAny(scopes.scopes);
    return router.app.$_canAll(scopes.scopes);
  });

  if (authorized) return next();
  return next('/');
});
